import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion, Select } from '@cimpress/react-components';
import { getAllDocuments } from 'src/selectors/layers';
import getSceneVariation from './selectors/getSceneVariation';
import EachSurfaceVariable from './EachSurfaceVariable';
import { toSelectOption } from 'src/util/misc';
import { updateConfiguration, removeConfiguration, addConfiguration } from './slice';
import { DEFAULT, SURFACE_ACTIONS } from './constants';
import { getDefaultForAttributeValues } from './common';

const SURFACE_CONFIG_KEY = 'surfaceConfigurations';

const SurfaceVariables = (props) => {
    const {
        surfaceConfigurations,
        addConfiguration,
        removeConfiguration,
        updateConfiguration,
        documents,
        attributesOptions,
    } = props;

    const onAttributeSelectionChange = (selected) => {
        const currentSeletions = surfaceConfigurations.map((config) => config.variableAttribute);
        const newSelections = selected?.map((select) => select.value) ?? [];

        // add new configurations for additional selected attributes
        newSelections.forEach((select) => {
            if (!currentSeletions.includes(select)) {
                const attributeSelected = attributesOptions.find((option) => option.name === select);
                const valueOptions = attributeSelected?.values ?? [];
                const defaultValues = getDefaultForAttributeValues(valueOptions, { action: DEFAULT });

                // set true values to increment page by 1 and leave everything else as the default
                Object.keys(defaultValues).forEach((key) => {
                    switch (key.toLocaleLowerCase()) {
                        case '1':
                        case 'true':
                            defaultValues[key] = { action: SURFACE_ACTIONS.INCREMENT, page: 1 };
                            break;
                        default:
                            // do nothing as the values already set to default
                            break;
                    }
                });

                addConfiguration({
                    key: SURFACE_CONFIG_KEY,
                    default: { variableAttribute: select, documents: [], values: defaultValues },
                });
            }
        });

        // remove any deselected attributes from configuration list
        currentSeletions.forEach((select, index) => {
            if (!newSelections.includes(select)) {
                removeConfiguration({ key: SURFACE_CONFIG_KEY, index });
            }
        });
    };

    const options = useMemo(() => {
        return toSelectOption(attributesOptions.map((option) => option.name));
    }, [attributesOptions]);

    return (
        <Accordion
            title={
                <div className='layers-header'>
                    <div className='layers-header__text'>Surface Variables</div>
                </div>
            }
            defaultOpen={false}>
            <div className='accordion-body-wrapper'>
                <Select
                    options={options}
                    value={
                        surfaceConfigurations.length > 0
                            ? toSelectOption(surfaceConfigurations.map((config) => config.variableAttribute))
                            : null
                    }
                    onChange={(chosenOption) => onAttributeSelectionChange(chosenOption)}
                    isClearable={false}
                    label='Select Attribute(s)'
                    isMulti={true}
                />

                {surfaceConfigurations.map((each, index) => (
                    <>
                        <EachSurfaceVariable
                            /* eslint-disable react/no-array-index-key */
                            key={index}
                            configuration={each}
                            attributesOptions={attributesOptions}
                            updateConfiguration={(data) =>
                                updateConfiguration({ key: SURFACE_CONFIG_KEY, index, newConfiguration: data })
                            }
                            removeConfiguration={() => removeConfiguration({ key: SURFACE_CONFIG_KEY, index })}
                            documents={documents}
                        />
                    </>
                ))}
            </div>
        </Accordion>
    );
};

SurfaceVariables.propTypes = {
    attributesOptions: PropTypes.arrayOf(PropTypes.object),
    surfaceConfigurations: PropTypes.arrayOf(
        PropTypes.shape({
            variableAttribute: PropTypes.string,
            values: PropTypes.object,
            documents: PropTypes.arrayOf(PropTypes.string),
        }),
    ),
    documents: PropTypes.arrayOf(PropTypes.any),
    updateConfiguration: PropTypes.func.isRequired,
    removeConfiguration: PropTypes.func.isRequired,
    addConfiguration: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { surfaceConfigurations } = getSceneVariation(state);
    const documents = getAllDocuments(state);

    return {
        surfaceConfigurations,
        documents,
    };
}

export default connect(mapStateToProps, {
    updateConfiguration,
    removeConfiguration,
    addConfiguration,
})(SurfaceVariables);
