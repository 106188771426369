import { call, put, takeEvery, select, all, takeLatest, delay } from 'redux-saga/effects';

import {
    ALL_DOCUMENTS,
    BLENDING_MODE_CHANGE,
    DOCUMENTS_REDO,
    DOCUMENTS_UNDO,
    LAYER_TOGGLE_HIDE,
    RELOAD,
} from 'src/store/actions/actionTypes';
import { CYLINDER_WARP } from 'src/models/documentWarps';
import { getAllBackgrounds, getAllDocuments, getSceneSize } from 'src/selectors/layers';
import { getSurfaceProperties } from 'src/selectors/pages/editor';
import { getRegionSetting } from 'src/selectors';
import { getLatestRequestCount, getSuccessRequestCount, getEditorDocImage, getCanvasXml } from 'src/selectors/canvas';
import { uploadSceneXml, generateUploadsUrl } from 'src/services/uploadsClient';
import { generateInstructionsUri, generatePreview } from 'src/services/renderingClient';
import currentStateToXml from 'src/util/xmlConverter/toXml';
import { downloadImage } from 'src/util/upload';
import { completeLoadingPage, setIsTenantSelectable, toggleShowCreateSceneModal } from 'src/slice';
import {
    backgroundUploaded,
    changeBackgroundOrder,
    cleanDisabledBackgrounds,
    disabledEditBackground,
    removeBackground,
    selectBackground,
    updateBackgroundRemovalSuccess,
} from 'src/pages/editor/components/backgrounds/slice';
import {
    renderCanvas,
    renderCanvasSuccess,
    renderCanvasFailed,
    updateDocumentReference,
    uploadDocumentImageSuccess,
    removeDocumentImage,
} from 'src/pages/editor/components/canvas/slice';
import {
    publishSceneSuccess,
    saveAndPublishSceneSuccess,
    saveDraftSuccess,
    updateXml,
} from 'src/pages/editor/components/scene/slice';
import backgroundSaga from 'src/pages/editor/components/backgrounds/saga';
import canvasSaga from 'src/pages/editor/components/canvas/saga';
import documentsSaga from 'src/pages/editor/components/documents/saga';
import overlaysSaga from 'src/pages/editor/components/overlays/saga';
import sceneSaga from 'src/pages/editor/components/scene/saga';
import surfacesSaga from 'src/pages/editor/components/surfaces/saga';
import { getScene } from 'src/services/sceneClient';
import { generateVersionContentUri, loadAssetUri } from 'src/services/assetsClient';
import { retrieveAllAndRequiredVariables } from 'src/services/attributeClient';
import { retrieveSurfaceData } from 'src/services/surfaceClient';
import { getProductVersions, retrieveProductData } from 'src/services/productClient';
import { loadSceneFailed, loadProductFailed, productIdMissing, SceneNotFound } from 'src/components/alert/slice';
import { logError } from 'src/loggingManager';
import fromXml from 'src/util/xmlConverter/fromXml';
import { translateSku } from 'src/components/skus/saga';
import { getLinksForAsset } from 'src/services/linkClient';
import { buildTransientDocument, buildURL, compressDocument } from 'src/util/cimdoc';
import {
    addDocument,
    removeDocument,
    maskUploaded,
    hideMask,
    removeMask,
    textureUploaded,
    changePage,
    setWarp,
    dragTransformPoint,
    editTransformPoint,
    editTransform,
    replaceTransforms,
    toggleEngraving,
    changeEngravingColor,
    toggleAutomask,
    cleanDisabledDocuments,
    disabledEditDocument,
} from './components/documents/slice';
import { changeOverlayOrder, overlayUploaded, removeOverlay } from './components/overlays/slice';

import { loadEditorPage } from './slice';
import { clearEditorSku, retrieveSurfacesSuccess } from './components/surfaces/slice';
import sceneConfigurationSagas from './components/SceneVariation/saga';
import { setCacheLinks, setLinks } from './components/SceneVariation/slice';
import { getLinkData } from './components/SceneVariation/service';
import getLinksData from './components/SceneVariation/selectors/getSceneDataLinks';
import getSceneVariation from './components/SceneVariation/selectors/getSceneVariation';

/**
 * MANY things can cause the canvas to render. Make sure to check that it is renderable each time.
 */
function* renderCanvasSaga() {
    const xml = yield select(getCanvasXml);
    const { variables, sku, skuVersion, list: surfaces } = yield select(getSurfaceProperties);

    if (!xml || (!!sku && (!surfaces || !surfaces.length))) {
        // You can't render the canvas without xml or (if sku is defined) surfaces
        return;
    }

    // Get the count of the current request, which is presently the latest request.
    const lastRequest = yield select(getLatestRequestCount);
    const requestCount = lastRequest + 1;
    yield put(renderCanvas(requestCount));

    // Generate the instructionsUrl to decide what to render on the scene.
    const { reference, image } = yield select(getEditorDocImage);
    let instructionsUri;

    if (reference) {
        // If the document reference exists use that
        instructionsUri = reference;
    } else if (image) {
        const cimdoc = buildTransientDocument(image);
        const compressed = compressDocument(cimdoc);
        instructionsUri = buildURL(compressed);
    } else {
        instructionsUri = generateInstructionsUri(sku, variables, skuVersion, image);
    }

    const region = yield select(getRegionSetting);
    const { width } = yield select(getSceneSize);
    // Attempt to upload the sceneXml
    try {
        const uploadId = yield call(uploadSceneXml, xml, region);
        const storedSceneUrl = generateUploadsUrl(region, uploadId);
        const renderedSceneUrl = generatePreview({
            url: storedSceneUrl,
            size: width,
            useWidth: true,
            region,
            instructionsUri,
        });
        // Attempt to download the rendered scene image.
        try {
            const renderedScene = yield call(downloadImage, renderedSceneUrl);

            // Only render the image if it is the newer than the last render
            const successRequestCount = yield select(getSuccessRequestCount);
            if (requestCount > successRequestCount) {
                const latestRequest = yield select(getLatestRequestCount);
                yield put(
                    renderCanvasSuccess({
                        url: renderedScene.src,
                        width: renderedScene.width,
                        height: renderedScene.height,
                        requestCount,
                        finalRender: requestCount === latestRequest,
                    }),
                );
            }
        } catch (error) {
            yield put(renderCanvasFailed(requestCount));
            logError(`Canvas download image failed! (xml=${xml}): ${renderedSceneUrl} ${error}`);
        }
    } catch (error) {
        yield put(renderCanvasFailed(requestCount));
        logError(`Canvas upload xml failed! (xml=${xml}): ${error}`);
    }

    if (surfaces.length === 0) {
        yield put(productIdMissing());
    }
}

/**
 * Should check the xml if it has updated. If it hasn't, the canvas shouldn't need to render.
 */
function* updateXmlAndRender() {
    try {
        const state = yield select();
        const oldXml = yield select(getCanvasXml);
        const newXml = currentStateToXml({ state, hideEnabled: true });
        if (
            newXml &&
            oldXml !== newXml &&
            !newXml.includes('size="undefined,undefined"') &&
            !newXml.includes('src="undefined"')
        ) {
            yield put(updateXml(newXml));
            yield call(renderCanvasSaga);
        }
    } catch (error) {
        logError(`failed to generate xml for some reason. This really should not happen: ${error}`);
    }
}

/**
 * Special case. When the warp is set, and it isn't set to cylinder, it should update the xml and render.
 */
function* setWarpCheck({ payload }) {
    if (payload.warpType !== CYLINDER_WARP) {
        yield call(updateXmlAndRender);
    }
}

function createSurfacesData(scene, skuData) {
    return {
        sku: '',
        skuVersion: undefined,
        merchantSku: undefined,
        merchantVersion: undefined,
        variables: scene.variables || {},
        ...skuData,
        list: [],
        isReady: !!skuData,
        isResolved: !!skuData,
    };
}

function createSkuData(skuData, versions, rules) {
    const key = skuData.sku && skuData.skuVersion ? `${skuData.sku}-${skuData.skuVersion}` : skuData.sku;
    const baseSku = {
        constraint: false,
        ruleSet: undefined,
        fetching: false,
        doesNotExist: false,
        noLinks: false,
        requiredVariables: [],
        versions,
    };
    const { version, ...withouVersions } = baseSku;

    if (!!skuData.sku && !!skuData.merchantSku) {
        return {
            [skuData.merchantSku || skuData.sku]: baseSku,
            [key]: { ...withouVersions, ...rules },
        };
    }

    if (skuData.skuVersion) {
        return {
            [skuData.sku]: { ...baseSku },
            [key]: { ...baseSku, ...rules },
        };
    }

    return {
        [key]: { ...baseSku, ...rules },
    };
}

function getSceneName(scene) {
    return scene.id ? scene.name : 'My Scene';
}

function getSceneDescription(scene) {
    return scene.id ? scene.description : 'My Description';
}

function loadSceneData(scene) {
    return {
        name: getSceneName(scene),
        description: getSceneDescription(scene),
        notes: scene.notes || '',
        id: scene.id,
        publishedVersionId: scene.publishedVersionId,
        latestVersionId: scene.latestVersionId,
        tags: scene.tags || [],
        xml: '',
        isModified: false,
        saving: false,
        publishing: false,
    };
}

function* loadLinkData(link) {
    try {
        const data = yield call(getLinkData, link);
        if (data) {
            yield put(setCacheLinks(data));
        }
    } catch {
        // eslint-disable-next-line no-console
        console.error('We were unable to load link ', link);
    }
}

function* loadLinksData(links) {
    yield all(links.map((link) => call(loadLinkData, link)));
}

function* checkAssets(params) {
    // reset the protected prop on all assets
    yield put(cleanDisabledBackgrounds());
    yield put(cleanDisabledDocuments());

    const backgrounds = yield select(getAllBackgrounds);
    const documents = yield select(getAllDocuments);
    const activeVariation = yield select(getSceneVariation);

    const bkgNames = {};
    const docNames = {};

    backgrounds.forEach((bkg) => {
        bkgNames[bkg.name] = bkg;
    });
    documents.forEach((doc) => {
        docNames[doc.id] = doc;
    });

    const links = yield select(getLinksData);
    const disabledBkgs = [];
    const disabledDocs = [];

    // When params exist it's a saving event, when is null is the loading event
    const data = params
        ? [...links.filter((lnk) => lnk.variableConfigurationId !== activeVariation.sceneVariableId), activeVariation]
        : links;
    data.forEach((link) => {
        link.productConfigurations.forEach((proConf) => {
            if (proConf.layer && bkgNames[proConf.layer.label]) {
                disabledBkgs.push(proConf.layer.label);
            }
            if (docNames[proConf.document]) {
                disabledDocs.push(proConf.document);
            }
        });

        link.sceneVariables.forEach((sceneConf) => {
            if (sceneConf.rules && sceneConf.rules.length > 0) {
                sceneConf.rules.forEach((rule) => {
                    if (rule.asset && bkgNames[rule.asset.label]) {
                        disabledBkgs.push(rule.asset.label);
                    }
                });
            }
        });
    });

    // disabled only the required assets
    yield all(disabledBkgs.map((name) => put(disabledEditBackground(name))));
    yield all(disabledDocs.map((id) => put(disabledEditDocument(id))));
}

// TODO this needs a massive rework...
function* loadPage({ payload }) {
    const { tenantType, tenantId, sceneId, versionId } = payload;
    yield put({ type: RELOAD, payload: {} });
    if (tenantType && tenantId && sceneId) {
        let productResults;
        let sceneData;
        let skuSurfaceData;
        let versions = [];
        let isV1Product = false;
        try {
            sceneData = yield call(getScene, { sceneId });
            let rulePromise = Promise.resolve({});
            let productPromise = Promise.resolve();
            let surfacePromise = Promise.resolve();
            if (sceneData.mcpSku) {
                try {
                    versions = yield call(getProductVersions, sceneData.mcpSku);
                } catch (error) {
                    try {
                        const V1Product = yield call(retrieveProductData, { sku: sceneData.mcpSku });
                        if (V1Product.name) {
                            versions = [];
                            isV1Product = true;
                        }
                    } catch {
                        throw Error('Product Version was not able to return');
                    }
                }

                const mcp = yield call(translateSku, {
                    sku: sceneData.mcpSku,
                    skuVersion: sceneData.mcpSkuVersion,
                    attributes: sceneData.variables,
                });
                if (mcp.platformId !== sceneData.mcpSku) {
                    skuSurfaceData = {
                        sku: mcp.platformId,
                        skuVersion: mcp.platformIdVersion,
                        variables: mcp.platformIdAttributes,
                        merchantSku: sceneData.mcpSku,
                        merchantVersion: sceneData.mcpSkuVersion,
                        merchantVariables: sceneData.variables,
                    };
                } else {
                    skuSurfaceData = {
                        sku: sceneData.mcpSku,
                        skuVersion: sceneData.mcpSkuVersion,
                        variables: sceneData.variables,
                        merchantSku: sceneData.mcpSku,
                        merchantVersion: sceneData.mcpSkuVersion,
                        merchantVariables: sceneData.variables,
                    };
                }
                productPromise = retrieveProductData({
                    sku: skuSurfaceData.sku,
                    skuVersion: skuSurfaceData.skuVersion,
                });
                rulePromise = retrieveAllAndRequiredVariables(skuSurfaceData.sku);
                surfacePromise = retrieveSurfaceData(
                    skuSurfaceData.sku,
                    skuSurfaceData.skuVersion,
                    skuSurfaceData.variables,
                );
            }

            const xmlPromise = loadAssetUri(
                generateVersionContentUri({ assetId: sceneId, versionId: versionId || sceneData.latestVersionId }),
            );

            productResults = yield all([xmlPromise, rulePromise, productPromise, surfacePromise]);
        } catch (e) {
            if (sceneData) {
                logError(
                    `fetching scene data failed, likely a change in product data, ${tenantId} ${tenantType} ${sceneId} ${sceneData.mcpSku} ${e && e.stack} ${e && e.toString()}`,
                );
                yield put(loadProductFailed(sceneData.mcpSku));
                // Retry to just load the scene without product data
                Object.assign(sceneData, { mcpSku: '', variables: {} });
                const xmlPromise = loadAssetUri(
                    generateVersionContentUri({ assetId: sceneId, versionId: versionId || sceneData.latestVersionId }),
                );
                productResults = yield all([xmlPromise, Promise.resolve({}), Promise.resolve(), Promise.resolve()]);
            } else {
                logError(
                    `scene id doesn't exist: ${tenantId} ${tenantType} ${sceneId} ${e && e.stack} ${e && e.toString()}`,
                );
                yield put(SceneNotFound(sceneId));
            }
        }

        const [xml, { ruleSet, requiredVariables }, product, surfaceData] = productResults;

        let skus;
        const surfaces = createSurfacesData(sceneData, skuSurfaceData);
        if (product) {
            skus = createSkuData(skuSurfaceData, versions, {
                ruleSet,
                requiredVariables: requiredVariables || [],
                productName: product.name,
                lastModified: product.modified,
                options: product.options,
                isV1Product,
            });

            surfaces.list = surfaceData[0].surfaces.map((surface, i) => ({
                index: i + 1,
                id: surface.id,
                name: surface.name,
                fullBleedArea: surface.fullBleedArea,
                trimArea: surface.trimArea,
                fullBleedAreas: surface.fullBleedAreas,
                trimAreas: surface.trimAreas,
                foldLines: surface.foldLines,
                width: surface.width,
                height: surface.height,
            }));
        }

        try {
            const { aspectRatio, ...xmlDetails } = fromXml(xml, sceneData.cylinderWarp);
            const data = {
                pages: {
                    editor: xmlDetails,
                },
            };
            if (aspectRatio) {
                const initialState = yield select();
                data.pages.editor.canvas = {
                    ...initialState.pages.editor.canvas,
                    aspectRatioConstrainedType: aspectRatio || '',
                };
            }
            data.pages.editor.scene = loadSceneData(sceneData);
            data.skus = skus;
            data.pages.editor.surfaces = surfaces;

            // Load Scene Links with Variation Configuration
            try {
                const links = yield call(getLinksForAsset, { assetId: sceneId });
                const sceneVariationConfigs = links.filter((link) => !!link.variableConfigurationId);
                yield put(setLinks({ links: sceneVariationConfigs }));

                yield call(loadLinksData, sceneVariationConfigs);
            } catch (e) {
                // Unable to load links should not prevent the user from editing the main scene
                logError(
                    `unable to load links, ${tenantId} ${tenantType} ${sceneId} ${e && e.stack} ${e && e.toString()}`,
                );
            }

            // Add the scene information here
            yield put({ type: RELOAD, payload: data });

            yield call(checkAssets);
        } catch (e) {
            logError(
                `loading scene failed, likely legacy, ${tenantId} ${tenantType} ${sceneId} ${e && e.stack} ${e && e.toString()}`,
            );
            yield put({ type: RELOAD, payload: {} });
            yield put(loadSceneFailed());
        }
    } else {
        yield put(toggleShowCreateSceneModal());
    }
    // TODO coamSelectionDisabled should be automatic once this page loads
    yield put(setIsTenantSelectable(!sceneId));
    yield put(completeLoadingPage('editor'));
    yield call(updateXmlAndRender);
}

function* handleClearEditorSku() {
    yield put(toggleAutomask(ALL_DOCUMENTS)); // Set automask false for all documents
    renderCanvasSaga();
}

function* debouncedUpdateXmlAndRender({ payload = {} }) {
    const { skipDelay } = payload;
    if (!skipDelay) {
        yield delay(500);
    }
    return yield call(updateXmlAndRender);
}

export default function* editorSagas() {
    return yield all([
        backgroundSaga(),
        canvasSaga(),
        documentsSaga(),
        overlaysSaga(),
        sceneSaga(),
        surfacesSaga(),
        sceneConfigurationSagas(),
        yield takeLatest(dragTransformPoint, debouncedUpdateXmlAndRender),
        yield takeLatest(DOCUMENTS_UNDO, debouncedUpdateXmlAndRender),
        yield takeLatest(DOCUMENTS_REDO, debouncedUpdateXmlAndRender),
        yield takeEvery(updateDocumentReference, renderCanvasSaga),
        yield takeEvery(uploadDocumentImageSuccess, renderCanvasSaga),
        yield takeEvery(removeDocumentImage, renderCanvasSaga),
        yield takeEvery(retrieveSurfacesSuccess, renderCanvasSaga),
        yield takeEvery(clearEditorSku, handleClearEditorSku),
        yield takeEvery(LAYER_TOGGLE_HIDE, updateXmlAndRender),
        yield takeEvery(toggleEngraving, updateXmlAndRender),
        yield takeEvery(toggleAutomask, updateXmlAndRender),
        yield takeEvery(changeEngravingColor, updateXmlAndRender),
        yield takeEvery(BLENDING_MODE_CHANGE, updateXmlAndRender),
        yield takeEvery(selectBackground, updateXmlAndRender),
        yield takeEvery(backgroundUploaded, updateXmlAndRender),
        yield takeLatest(changeBackgroundOrder, updateXmlAndRender),
        yield takeLatest(changeOverlayOrder, updateXmlAndRender),
        yield takeEvery(replaceTransforms, updateXmlAndRender),
        yield takeEvery(addDocument, updateXmlAndRender),
        yield takeEvery(removeDocument, updateXmlAndRender),
        yield takeEvery(maskUploaded, updateXmlAndRender),
        yield takeEvery(textureUploaded, updateXmlAndRender),
        yield takeEvery(hideMask, updateXmlAndRender),
        yield takeEvery(removeMask, updateXmlAndRender),
        yield takeEvery(changePage, updateXmlAndRender),
        yield takeEvery(setWarp, setWarpCheck),
        yield takeEvery(overlayUploaded, updateXmlAndRender),
        yield takeEvery(removeOverlay, updateXmlAndRender),
        yield takeLatest(editTransformPoint, debouncedUpdateXmlAndRender),
        yield takeEvery(editTransform, updateXmlAndRender),
        yield takeEvery(loadEditorPage, loadPage),
        yield takeEvery(saveAndPublishSceneSuccess, checkAssets),
        yield takeEvery(publishSceneSuccess, checkAssets),
        yield takeEvery(saveDraftSuccess, checkAssets),
        yield takeEvery(updateBackgroundRemovalSuccess, updateXmlAndRender),
        yield takeEvery(removeBackground, updateXmlAndRender),
    ]);
}
