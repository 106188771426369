import type { RootState } from 'src/store';
import { connect } from 'react-redux';

import { getSurfaceProperties } from 'src/selectors/pages/editor';
import { applyVariables, unresolveVariables } from './slice';
import VariablesV3 from 'src/components/product/variables/variablesv3';
import { AttributeChangeType } from '@rendering/components';
import { AttributesToObject } from 'src/util/misc';
import auth from 'src/auth';

export const VariablesContainerV3 = connect(
    (state: RootState) => {
        const data = getSurfaceProperties(state);
        const token = auth.getAccessToken() || '';

        return {
            sku: data.merchantSku!,
            skuVersion: data.merchantVersion,
            variables: data.merchantVariables,
            isReady: data.isReady,
            token,
        };
    },
    {
        onChangeVariables: (sku: string, skuVersion: number, changes: AttributeChangeType) => {
            return applyVariables({
                sku,
                skuVersion,
                resolved: changes.isReady && !changes.attributes?.some((i) => i.resolvedValue === undefined),
                variables: AttributesToObject(changes.attributes),
            });
        },
        onFailure: unresolveVariables,
    },
)(VariablesV3);
