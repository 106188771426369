import React from 'react';
import { Accordion } from '@cimpress/react-components';

import SceneTags from 'src/pages/editor/components/save/sceneTags';
import { VariablesContainerV3 } from '../surfaces/variables';
import DocRefSelector from '../scene/docRef';
import ImageSelector from '../scene/image';
import Product from '../scene/product';
import Properties from '../save/properties';
import Notes from '../save/notes';

const InformationPanel = () => {
    return (
        <div className='information-panel'>
            <Accordion defaultOpen={true} title='Name and Description'>
                <Properties />
            </Accordion>
            <Accordion defaultOpen={true} title='Tags'>
                <SceneTags />
            </Accordion>
            <Accordion defaultOpen={true} title='Product Information'>
                <div className='information-panel__product'>
                    <React.StrictMode>
                        <Product />
                        <VariablesContainerV3 />
                        <DocRefSelector />
                        <ImageSelector />
                    </React.StrictMode>
                </div>
            </Accordion>
            <Accordion defaultOpen={true} title='Note'>
                <Notes />
            </Accordion>
        </div>
    );
};

export default InformationPanel;
