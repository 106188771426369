import { createSelector } from 'reselect';
import { RootState } from 'src/store';

const getSurf = (state: RootState) => state.pages.editor.surfaces;

export const getProductDetails = createSelector(getSurf, (data) => data);
export const getListSurfaces = createSelector(getSurf, (data) => data.list);
export const getVariables = createSelector(getSurf, (data) => data.variables);

const getSurfaceProperties = createSelector([getProductDetails], (data) => data);

export default getSurfaceProperties;
