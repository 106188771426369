/* eslint-disable no-underscore-dangle */
import { apiPrefix } from 'src/settings';
import { logError } from 'src/loggingManager';
import { parseError } from 'src/util/misc';
import { makeAuthenticatedServiceRequest } from './servicesClient';
import { retrievePlatformId } from './productClient';
import { LinkInputType, LinkResponseType, LinkType } from 'src/types/link';
import { APIError } from 'src/types/index';

const ASSET_ENDPOINT = `https://${apiPrefix}assets.documents.cimpress.io/v3/assets`;
const SETTINGS_ENDPOINT = 'https://settings.cimpress.io/v1/settings?settingName=assetLinkingSettings';

/**
 * The values needed for linking an asset to a product
 * @typedef {Object} LinkInput
 * @property {string} ReferenceId A product sku to associate an asset with
 * @property {string[]} [SubReferenceIds] List of surfaces of the product that the asset is associated with
 * @property {Object} [Attributes] Product variables for the sku
 * @property {string} Purpose - The purpose of the asset (design, product, merchandising)
 * @property {string} SubPurpose The sub purpose of the asset. For design scenes sub purposes include underlay and overlay
 * @property {string[]} [Tags] List of tags for the asset
 */

/**
 * Generates a URI to an asset's link
 * @param options
 * @param options.assetId - the ID of the asset
 * @param options.linkId - the ID of the link
 * @returns
 */
export function generateLinkUri({ assetId, linkId }: { assetId: string; linkId: string }) {
    return `${ASSET_ENDPOINT}/${assetId}/links/${linkId}`;
}

/**
 * Links a scene to a product.
 * @param options
 * @param options.assetId - The ID of the asset being linked
 * @param options.linkInput - The object containing link properties.
 * @returns - The promise of the request.
 */
export function createLink({ assetId, linkInput }: { assetId: string; linkInput: LinkInputType }) {
    const url = `${ASSET_ENDPOINT}/${assetId}/links`;
    return makeAuthenticatedServiceRequest({ method: 'post', url, data: linkInput }).catch((e) => {
        logError(`create asset link failed! ${parseError(e)} ${url} ${JSON.stringify(linkInput)}`);
        throw e;
    });
}

export function deleteLink({ linkUri }: { linkUri: string }) {
    return makeAuthenticatedServiceRequest({ method: 'delete', url: linkUri, header: { 'If-Match': '*' } }).catch(
        (e) => {
            logError(`delete asset link failed! ${parseError(e)} ${linkUri}`);
            throw e;
        },
    );
}

/**
 * Links a scene to a product.
 * @param options
 * @param options.oldLinkUri - The old link to delete.
 * @param options.assetId - the ID of the asset
 * @param options.linkInput - The object containing link properties.
 * @returns - The promise of the request.
 */
export function replaceLink({
    oldLinkUri,
    linkInput,
    assetId,
}: {
    oldLinkUri: string;
    linkInput: LinkInputType;
    assetId: string;
}) {
    return deleteLink({ linkUri: oldLinkUri }).then(() => createLink({ assetId, linkInput }));
}

/**
 *  Links a scene to a product.
 * @param options
 * @param options.linkId - The existing link id.
 * @param options.assetId - The ID of the asset
 * @param options.linkInput - The object containing link properties.
 * @returns - The promise of the request.
 */
export function updateLink({
    linkInput,
    assetId,
    linkId,
}: {
    linkId: string;
    linkInput: LinkInputType;
    assetId: string;
}) {
    const url = `${ASSET_ENDPOINT}/${assetId}/links/${linkId}`;
    return makeAuthenticatedServiceRequest({ method: 'put', url, data: linkInput, header: { 'If-Match': '*' } }).catch(
        (e) => {
            logError(`update asset link failed! ${parseError(e)} ${url} ${JSON.stringify(linkInput)}`);
            throw e;
        },
    );
}

function mapLink(link: LinkResponseType): Promise<LinkType> {
    const output: LinkType = {
        sceneId: link.assetId,
        linkId: link.id,
        sku: link.referenceId,
        skuVersion: link.referenceIdVersion || 0,
        merchantSku: link.referenceId,
        merchantSkuVersion: link.referenceIdVersion || 0,
        merchantVariables: link.attributes || {},
        variableConfigurationId: link.variableConfigurationId,
        purpose: link.purpose,
        linkPurposes: link.linkPurposes,
        tenant: link.tenant,
        subPurpose: link.subPurpose,
        variables: link.attributes || {},
        tags: link.tags || [],
        surfaces: link.subReferenceIds ? link.subReferenceIds.map((id) => ({ id })) : [],
        view: link?.view,
        _links: link._links,
    };

    if (link.referenceId && link.referenceId.startsWith('PRD')) {
        try {
            return retrievePlatformId({
                sku: link.referenceId,
                skuVersion: link.referenceIdVersion,
                attributes: link.attributes,
            }).then(({ merchantSku, merchantVersion, merchantVariables }) => {
                output.merchantSku = merchantSku;
                output.merchantSkuVersion = merchantVersion;
                output.merchantVariables = merchantVariables;
                return output;
            });
        } catch (error) {
            logError(`Failed to retrieve the VP platform id: ${link.referenceId} ${error}`);
            throw error;
        }
    }

    return Promise.resolve(output);
}

function mapLinks(results: LinkResponseType[]) {
    return Promise.all(results.map(mapLink));
}

/**
 * Gets all links for an asset
 * @param options
 * @param options.assetId The ID of the asset
 * @returns - The promise of the request.
 */
export function getLinksForAsset({ assetId }: { assetId: string }) {
    const url = `${ASSET_ENDPOINT}/${assetId}/links?pageSize=100`;
    return makeAuthenticatedServiceRequest({ url })
        .then((response) => mapLinks(response.data._embedded.item))
        .catch((e) => {
            logError(`query asset error (url=${url}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Query for links based on sku
 * @param options
 * @param options.tenantType - The type of owner resource (merchant / fulfiller).
 * @param options.tenantId - The id of the resource.
 * @param options.sku - The sku to query for.
 * @returns - The promise of the request.
 */
export function queryLinks({
    tenantType,
    tenantId,
    sku,
    variables,
    purpose,
}: {
    tenantType: string;
    tenantId: string;
    sku: string;
    variables: Record<string, any>;
    purpose: string;
}) {
    const data = {
        ReferenceId: sku,
        Tenant: {
            Id: tenantId,
            Type: tenantType,
        },
        Attributes: variables,
        Purpose: purpose,
    };

    const url = `${ASSET_ENDPOINT}/-/links:query?pageSize=200`;

    return makeAuthenticatedServiceRequest({
        method: 'post',
        url,
        data,
    })
        .then((response) =>
            response.data._embedded.item.filter(
                (link: LinkResponseType) => link.tenant.id === tenantId && link.tenant.type === tenantType,
            ),
        )
        .then(mapLinks)
        .catch((e) => {
            logError(`Query links error (data=${JSON.stringify(data)}): ${parseError(e)}`);
            throw e;
        });
}

/**
 * Gets all settings for tenant
 * @param tenantType
 * @param tenantId
 * @returns - The promise of the request.
 */
export async function getPurposeSettings(tenantType: string, tenantId: string) {
    const url = `${SETTINGS_ENDPOINT}&resourceType=${tenantType}&resourceId=${tenantId}`;
    try {
        const { data: settingsInfo } = await makeAuthenticatedServiceRequest({ url });
        if ((settingsInfo._embedded.item || []).length) {
            const { subPurposes, tags } = settingsInfo._embedded.item[0].settings.settings;
            return { subPurposes, tags };
        }
        return [];
    } catch (error: unknown) {
        logError(`query asset error (url=${url}): ${parseError(error as APIError)}`);
        throw error;
    }
}
