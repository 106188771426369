/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import { Tooltip } from '@cimpress/react-components';
import SceneVariation from '../SceneVariation';
import EditLayers from '../layer';
import EditInformation from './informationPanel';
import ConfirmModal from '../confirm';

interface EditControlsProps {
    invalidSkuSetup?: boolean;
    confirm?: boolean;
    useV2Component?: boolean;
    setConfirm?: (value: boolean) => void;
}

interface EditControlsState {
    panel: string;
    showModal: boolean;
}

const EDIT_LAYERS = 'editLayers';
const EDIT_INFORMATION = 'editInformation';
const SCENE_VARIATION = 'sceneVariation';

function getPanelView(panel: string) {
    switch (panel) {
        case EDIT_LAYERS:
            return EditLayers;
        case SCENE_VARIATION:
            return SceneVariation;
        case EDIT_INFORMATION:
        default:
            return EditInformation;
    }
}

function getPanelItem(
    selected: boolean,
    onClick: () => void,
    panelIcon: string,
    text: string,
    error: string | null,
    id = `panel__item_${text}`,
) {
    return (
        <li data-testid={id} className={`panels__item ${selected ? 'panels__item--selected' : ''}`} onClick={onClick}>
            <img className={`icon3x ${selected ? 'colorOceanBase' : 'colorGrey'}`} src={panelIcon} />
            <div className='panels__text'>{text}</div>
            {error && (
                <Tooltip
                    className='panels__error-tooltip'
                    containerClassName='panels__error'
                    direction='top'
                    contents={error}>
                    <img
                        className='iconNl colorOceanBase'
                        src='https://static.ux.cimpress.io/assets/icons/report-problem-triangle-f.svg'
                    />
                </Tooltip>
            )}
        </li>
    );
}

export default class EditControls extends PureComponent<EditControlsProps, EditControlsState> {
    state: EditControlsState = {
        panel: EDIT_INFORMATION,
        showModal: false,
    };

    onClickPanel = (panel: string) => {
        this.setState({ panel });
    };

    onSceneVariationClick = () => {
        if (this.props.confirm === true) {
            this.onClickPanel(SCENE_VARIATION);
        } else {
            this.setState({ showModal: true });
        }
    };

    onConfirmSceneVariation = (answer: boolean) => {
        if (answer) {
            this.onClickPanel(SCENE_VARIATION);
        }
        this.props.setConfirm?.(answer);
        this.setState({ showModal: false });
    };

    render() {
        const { panel, showModal } = this.state;
        const { invalidSkuSetup } = this.props;
        const layersIcon = 'https://static.ux.cimpress.io/assets/icons/layers-show-l.svg';
        const fileSettingIcon = 'https://static.ux.cimpress.io/assets/icons/file-picture-setting-l.svg';
        const variableScenesIcon = 'https://static.ux.cimpress.io/assets/icons/settings-1-l.svg';

        let skuError: string | null = null;
        if (panel !== EDIT_INFORMATION && invalidSkuSetup) {
            skuError =
                'Required information about the product SKU that was entered is missing. Please fill in the required attributes to continue.';
        }
        const Controls = getPanelView(panel);
        return (
            <div className='left-panel'>
                <ul className='panels'>
                    {getPanelItem(
                        panel === EDIT_INFORMATION,
                        () => this.onClickPanel(EDIT_INFORMATION),
                        fileSettingIcon,
                        'Scene Information',
                        skuError,
                        'editor-panel-information',
                    )}
                    {getPanelItem(
                        panel === EDIT_LAYERS,
                        () => this.onClickPanel(EDIT_LAYERS),
                        layersIcon,
                        'Scene Layers',
                        null,
                        'editor-panel-scene',
                    )}
                    {getPanelItem(
                        panel === SCENE_VARIATION,
                        this.onSceneVariationClick,
                        variableScenesIcon,
                        'Scene Variation',
                        null,
                        'scene-variation',
                    )}
                </ul>
                <Controls />
                <ConfirmModal showModal={showModal} title='Scene Variation' onConfirm={this.onConfirmSceneVariation}>
                    <>
                        <p>
                            {' '}
                            Scene Variation is an optional new feature of Scene Maker. Scene Variation can be used to
                            create multiple iterations of a scene that vary based on a configured ruleset.{' '}
                        </p>
                        <p>
                            {' '}
                            Click "Continue" to start using Scene Variation configuration. Click "Cancel" to continue
                            with a fixed scene configuration.
                        </p>
                    </>
                </ConfirmModal>
            </div>
        );
    }
}
