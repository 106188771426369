/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { isEqual } from 'lodash';
import { logError } from 'src/loggingManager';
import { updateAsset } from 'src/services/assetsClient';
import { updateLink } from 'src/services/linkClient';
import { generatePreviewUsingSku } from 'src/services/renderingClient';
import { createSceneVersion } from 'src/services/sceneClient';
import { uploadJSONFile, getUrlById } from 'src/services/uploadsClient';
import { apiPrefix } from 'src/settings';
import { parseError } from 'src/util/misc';
import { makeAuthenticatedServiceRequest } from '../../../../services/servicesClient';
import {
    ASSET_ACTIONS,
    PRODUCT_VARIABLE_TYPES,
    SCENE_VARIABLE_TYPES,
    MERCHANDISING,
    DOCUMENT_ACTIONS,
    SURFACE_ACTIONS,
    DEFAULT,
} from './constants';
import { BLANK_STATE } from './slice';

const SCENE_ENDPOINT = `https://${apiPrefix}scenes.documents.cimpress.io`;

function getOperation(assetAction) {
    switch (assetAction) {
        case ASSET_ACTIONS.REMOVE:
            return 'remove';
        case ASSET_ACTIONS.FILL:
        case ASSET_ACTIONS.REPLACE:
            return 'replace';
        case PRODUCT_VARIABLE_TYPES.blending:
            return DOCUMENT_ACTIONS.BLENDING_MODE;
        default:
            return 'none';
    }
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getAssetImage(bkgName) {
    return {
        value: '',
        label: bkgName,
    };
}

export function getSceneVariationContent({ id }) {
    // TODO: Replace this with the latest revision URL from the QueryLinks
    const url = `${SCENE_ENDPOINT}/v3/assets/${id}/content?guid=${crypto.randomUUID()}`;
    return makeAuthenticatedServiceRequest({ url, method: 'get' }).catch((e) => {
        logError(`Failure to import scene! ${parseError(e)}`);
        throw e;
    });
}

export function mapPurposes(scenePurposes) {
    return scenePurposes.map((pur) => {
        const inxPoint = pur.value.indexOf(':');
        if (inxPoint >= 0) {
            return { purpose: pur.value.substr(0, inxPoint), subPurpose: pur.value.substr(inxPoint + 1) };
        }

        return { purpose: pur.value, subPurpose: pur.value };
    });
}

export function mapScenePurposes(purposes) {
    return purposes.map((item) => {
        if (item.purpose && item.subPurpose) {
            const value = `${item.purpose}:${item.subPurpose}`;
            if (item.purpose === MERCHANDISING.value) {
                return { label: `${MERCHANDISING.label} ${item.subPurpose}`, value };
            }
            return { label: `${capitalize(item.purpose)} ${capitalize(item.subPurpose)}`, value };
        }

        return { label: 'WRONG PURPOSE', value: null };
    });
}

export function mapBlendingConditions(conditions) {
    const output = {};
    Object.keys(conditions).forEach((item) => {
        output[item] = conditions[item].blendingMode || 'normal';
    });

    return output;
}

export function mapSurfaceConditions(conditions) {
    const output = {};
    Object.keys(conditions).forEach((item) => {
        const action = SURFACE_ACTIONS[conditions[item].operation.toUpperCase()];
        output[item] = action ? { action, page: conditions[item].page } : { action: DEFAULT };
    });

    return output;
}

// PARAMS
// sceneData: { sku, skuVersion, fixedAttributesSelection: { [string]: string }, productConfigurations: [ { variableOption, layer: { value, label }, variableAttribute, values: { [string]: string } ],
// scenePurposes: [ string ], sceneVariables: [ { purpose, rules: [ { id, type, asset: { label, value }, action, file } ] } ] }
export function mapDataToDoc(sceneId, sceneData) {
    const productMapping = {};
    const sceneVariables = {};
    const documents = {};
    const effects = [];

    sceneData.sceneVariables.forEach((sceneVar) => {
        sceneVar.rules.forEach((rule) => {
            if (!rule || !rule.type) {
                return;
            }

            if (rule.type === SCENE_VARIABLE_TYPES['Asset Change']) {
                if (sceneVariables[rule.asset.label]) {
                    sceneVariables[rule.asset.label].conditions[sceneVar.purpose] = {
                        operation: getOperation(rule.action),
                    };
                } else {
                    sceneVariables[rule.asset.label] = {
                        asset: rule.asset,
                        variable: {
                            type: 'purpose',
                        },
                        conditions: {
                            [sceneVar.purpose]: {
                                operation: getOperation(rule.action),
                            },
                        },
                    };
                }

                if (rule.action === ASSET_ACTIONS.FILL) {
                    sceneVariables[rule.asset.label].conditions[sceneVar.purpose].fillColor = rule.fillColor;
                } else if (rule.action === ASSET_ACTIONS.REPLACE) {
                    sceneVariables[rule.asset.label].conditions[sceneVar.purpose].file = rule.file;
                }
            } else {
                const cropping = effects.find((item) => item.variable.type === SCENE_VARIABLE_TYPES.Cropping);
                if (cropping) {
                    const effectConditions = cropping.conditions;
                    effectConditions[sceneVar.purpose] = {
                        crop: {
                            x: rule.x0,
                            y: rule.y0,
                            width: rule.x1 - rule.x0,
                            height: rule.y1 - rule.y0,
                        },
                    };
                } else {
                    effects.push({
                        variable: {
                            type: 'purpose',
                        },
                        conditions: {
                            [sceneVar.purpose]: {
                                crop: {
                                    x: rule.x0,
                                    y: rule.y0,
                                    width: rule.x1 - rule.x0,
                                    height: rule.y1 - rule.y0,
                                },
                            },
                        },
                    });
                }
            }
        });
    });

    sceneData.productConfigurations.forEach((conf) => {
        const conditions = {};
        if (conf && conf.variableOption === PRODUCT_VARIABLE_TYPES.product) {
            Object.keys(conf.values).forEach((key) => {
                conditions[key] = {
                    operation: getOperation(conf.values[key].action),
                    asset: {
                        uri:
                            conf.values[key].action === ASSET_ACTIONS.REPLACE
                                ? conf.values[key].value.imageUrl
                                : undefined,
                        name:
                            conf.values[key].action === ASSET_ACTIONS.REPLACE ? conf.values[key].value.name : undefined,
                        color: conf.values[key].action === ASSET_ACTIONS.FILL ? conf.values[key].value : undefined,
                    },
                };

                if (['none', 'remove'].includes(conditions[key].operation)) {
                    delete conditions[key].asset;
                }
            });

            productMapping[conf.layer.label] = {
                asset: conf.layer,
                variable: {
                    type: 'attributes',
                    name: conf.variableAttribute,
                },
                conditions,
            };
        }

        if (conf && conf.variableOption === PRODUCT_VARIABLE_TYPES.blending) {
            Object.keys(conf.values).forEach((key) => {
                const operation = getOperation(conf.variableOption);
                conditions[key] = { [operation]: conf.values[key] };
            });

            documents[`${conf.document}-wrapper`] = [
                {
                    variable: {
                        type: 'attributes',
                        name: conf.variableAttribute,
                    },
                    conditions,
                },
            ];
        }
    });

    /**
     * Internal State Contract for surfaceConfigurations
     * [{
     *   documents: string[],
     *   variableAttribute: string // attribute name
     *   values: {
     *     [key: string]: { // key is the attribute values
     *       action: string, // INCREMENT | DECREMENT | REPLACE | Use Default
     *       page: number
     *     }
     *   }
     * }]
     */
    sceneData.surfaceConfigurations.forEach((conf) => {
        conf.documents.forEach((docKey) => {
            let conditions = {};
            Object.keys(conf.values).forEach((key) => {
                if (conf.values[key].action === DEFAULT) {
                    conditions[key] = { operation: 'none' };
                } else {
                    conditions[key] = {
                        operation: conf.values[key].action.toLowerCase(),
                        page: conf.values[key].page,
                    };
                }
            });

            const docItem = {
                variable: {
                    type: 'attributes',
                    name: conf.variableAttribute,
                },
                conditions,
            };

            if (documents[docKey]) {
                documents[docKey].push(docItem);
            } else {
                documents[docKey] = [docItem];
            }
        });
    });

    return {
        sceneUri: `https://${apiPrefix}assets.documents.cimpress.io/v3/assets/${sceneId}/content`,
        linkPurposes: mapPurposes(sceneData.scenePurposes),
        images: { ...productMapping, ...sceneVariables },
        documents,
        effects,
    };
}

export function mapDocToData(output, sceneConfigurationData) {
    const { images, effects, documents } = sceneConfigurationData;

    images &&
        Object.keys(images).forEach((imageKey) => {
            const data = images[imageKey];
            if (data.variable.type === 'attributes') {
                const mapToValues = {};
                Object.keys(data.conditions).forEach((condition) => {
                    const item = {
                        action: DEFAULT,
                    };

                    if (data.conditions[condition].asset && data.conditions[condition].asset.uri) {
                        item.action = ASSET_ACTIONS.REPLACE;
                        item.value = {
                            name: data.conditions[condition].asset.name,
                            imageUrl: data.conditions[condition].asset.uri,
                        };
                    } else if (data.conditions[condition].asset && data.conditions[condition].asset.color) {
                        item.action = ASSET_ACTIONS.FILL;
                        item.value = data.conditions[condition].asset.color;
                    } else if (data.conditions[condition].operation === 'remove') {
                        item.action = ASSET_ACTIONS.REMOVE;
                    }

                    mapToValues[condition] = item;
                });
                output.productConfigurations.push({
                    variableOption: PRODUCT_VARIABLE_TYPES.product,
                    layer: data.asset,
                    document: null,
                    variableAttribute: data.variable.name,
                    values: { ...mapToValues },
                });
            } else if (data.variable.type === 'purpose') {
                Object.keys(data.conditions).forEach((purpose) => {
                    if (data.conditions[purpose].operation) {
                        const curPurposeVariable = output.sceneVariables.find((item) => item.purpose === purpose);
                        if (curPurposeVariable) {
                            const newItem = {
                                id: curPurposeVariable.rules.length,
                                type: SCENE_VARIABLE_TYPES['Asset Change'],
                                asset: data.asset,
                            };
                            if (data.conditions[purpose].file) {
                                newItem.action = ASSET_ACTIONS.REPLACE;
                                newItem.file = data.conditions[purpose].file;
                            } else if (data.conditions[purpose].fillColor) {
                                newItem.action = ASSET_ACTIONS.FILL;
                                newItem.fillColor = data.conditions[purpose].fillColor;
                            } else if (data.conditions[purpose].operation === 'remove') {
                                newItem.action = ASSET_ACTIONS.REMOVE;
                            }
                            curPurposeVariable.rules.push(newItem);
                        } else {
                            const sceneVar = {
                                purpose,
                                rules: [
                                    {
                                        id: 0,
                                        type: SCENE_VARIABLE_TYPES['Asset Change'],
                                        asset: data.asset,
                                    },
                                ],
                            };
                            if (data.conditions[purpose].file) {
                                sceneVar.rules[0].action = ASSET_ACTIONS.REPLACE;
                                sceneVar.rules[0].file = data.conditions[purpose].file;
                            } else if (data.conditions[purpose].fillColor) {
                                sceneVar.rules[0].action = ASSET_ACTIONS.FILL;
                                sceneVar.rules[0].fillColor = data.conditions[purpose].fillColor;
                            } else if (data.conditions[purpose].operation === 'remove') {
                                sceneVar.rules[0].action = ASSET_ACTIONS.REMOVE;
                            }

                            output.sceneVariables.push(sceneVar);
                        }
                    }
                });
            }
        });

    documents &&
        Object.keys(documents).forEach((docKey) => {
            // data here can be an object (VariableScene V1) or an array of objects (VariableScene V2)
            let docData = documents[docKey];
            if (!docData) return;

            if (!Array.isArray(docData)) {
                docData = [docData];
            }

            docData.forEach((data) => {
                // product variable: blending mode
                if (Object.values(data.conditions).find((condition) => !!condition.blendingMode)) {
                    output.productConfigurations.push({
                        variableOption: PRODUCT_VARIABLE_TYPES.blending,
                        layer: null,
                        document: docKey.replaceAll('-wrapper', ''),
                        variableAttribute: data.variable?.name ?? '',
                        values: mapBlendingConditions(data.conditions),
                    });
                }

                // surface variable: page
                if (Object.values(data.conditions).find((condition) => !!condition.page)) {
                    const surfaceConfig = output.surfaceConfigurations.find(
                        (item) => item.variableAttribute === data.variable?.name,
                    );

                    const values = mapSurfaceConditions(data.conditions);
                    if (
                        surfaceConfig &&
                        isEqual(surfaceConfig.values, values) &&
                        !surfaceConfig.documents.includes(docKey)
                    ) {
                        surfaceConfig.documents.push(docKey);
                    } else {
                        output.surfaceConfigurations.push({
                            documents: [docKey],
                            variableAttribute: data.variable?.name ?? '',
                            values,
                        });
                    }
                }
            });
        });

    effects.forEach((effect) => {
        if (effect.variable.type === 'purpose') {
            Object.keys(effect.conditions).forEach((purpose) => {
                const curVariable = output.sceneVariables.find((item) => item.purpose === purpose);

                // Purpose config already exists
                if (curVariable) {
                    const cropConfig = {
                        id: curVariable.rules.length,
                        type: SCENE_VARIABLE_TYPES.Cropping,
                        x0: +effect.conditions[purpose].crop.x,
                        y0: +effect.conditions[purpose].crop.y,
                        x1: +effect.conditions[purpose].crop.x + effect.conditions[purpose].crop.width,
                        y1: +effect.conditions[purpose].crop.y + effect.conditions[purpose].crop.height,
                    };
                    curVariable.rules.push(cropConfig);
                } else {
                    const effectConfig = {
                        purpose,
                        rules: [
                            {
                                id: 0,
                                type: SCENE_VARIABLE_TYPES.Cropping,
                                x0: +effect.conditions[purpose].crop.x,
                                y0: +effect.conditions[purpose].crop.y,
                                x1: +effect.conditions[purpose].crop.x + effect.conditions[purpose].crop.width,
                                y1: +effect.conditions[purpose].crop.y + effect.conditions[purpose].crop.height,
                            },
                        ],
                    };

                    output.sceneVariables.push(effectConfig);
                }
            });
        }
    });

    // Only map productVariables and SceneVariables as purposes are taking from the Link
    return output;
}

export function getLinkData(link) {
    const output = {
        ...BLANK_STATE,
        sku: link.sku,
        skuVersion: link.skuVersion,
        fixedAttributesSelection: link.variables,
        scenePurposes: mapScenePurposes(link.linkPurposes),
        sceneVariableId: link.variableConfigurationId,
        sceneLinkId: link.linkId,
        sceneId: link.sceneId,
        tags: link.tags,
        productConfigurations: [],
        surfaceConfigurations: [],
        sceneVariables: [],
        view: link.view,
    };

    if (link._links && link._links.variableConfigurationContent && link._links.variableConfigurationContent.href) {
        return makeAuthenticatedServiceRequest({
            url: link._links.variableConfigurationContent.href,
            method: 'GET',
        }).then((res) => mapDocToData(output, res.data));
    }

    return getSceneVariationContent({ id: link.variableConfigurationId }).then((res) => mapDocToData(output, res.data));
}

// / params
// / res: { "count", "total", "offset", "_links": { "prev", "self": { "href" }, "next" }, "_embedded": { "item": [{ _links: { ...assetLinkData } }] }
export function findConfigLinks(res, variableConfigurationId = '') {
    if (res.data._embedded && res.data._embedded.item && res.data._embedded.item.length > 0) {
        const links =
            variableConfigurationId !== ''
                ? res.data._embedded.item.filter((scene) => scene.variableConfigurationId === variableConfigurationId)
                : [];
        return links.length > 0 ? links[0] : null;
    }
    return null;
}

// eslint-disable-next-line import/prefer-default-export
export function getSceneVariationLink({ sceneId, variableConfigurationId }) {
    const url = `${SCENE_ENDPOINT}/v3/assets/${sceneId}/links`;

    return makeAuthenticatedServiceRequest({ url, method: 'get' })
        .then((res) => findConfigLinks(res, variableConfigurationId))
        .catch((e) => {
            logError(`Failure to import scene! ${parseError(e)}`);
            throw e;
        });
}

function mapSceneToAsset(scene) {
    const { name, description, notes, tags, sku, skuVersion, tenant, attributes, initialRevision, ...otherFields } =
        scene;
    return {
        name,
        description,
        notes,
        tags,
        referenceId: sku,
        referenceIdVersion: skuVersion,
        tenant,
        attributes,
        assetType: 'variableScene',
        ...otherFields,
    };
}

export function saveSceneVariationAsset({ scene, configData, variableConfigurationId }) {
    const url = `${SCENE_ENDPOINT}/v3/assets`;
    const dataToDoc = mapDataToDoc(scene.id, configData);
    if (variableConfigurationId) {
        return uploadJSONFile(dataToDoc)
            .then((uploadId) => {
                const initialVersion = { indexUri: getUrlById(uploadId) };
                return createSceneVersion({ sceneId: variableConfigurationId, version: initialVersion })
                    .then((newVersion) => {
                        const { id: _id, ...sceneData } = scene;
                        const updatedScene = Object.assign(
                            {},
                            sceneData,
                            { initialVersion },
                            { publishedRevisionId: newVersion.data.id },
                            { latestRevisionId: newVersion.data.id },
                        );
                        return updateAsset({
                            tenantType: scene.tenant.type,
                            tenantId: scene.tenant.id,
                            assetId: variableConfigurationId,
                            newAsset: updatedScene,
                        })
                            .then((res) => res.data)
                            .catch((e) => {
                                logError(`Failure to Update scene! ${parseError(e)}`);
                                throw e;
                            });
                    })
                    .catch((e) => {
                        logError(`Failure to create scene version! ${parseError(e)}`);
                        throw e;
                    });
            })
            .catch((e) => {
                logError(`Failure to Upload JSON File! ${parseError(e)}`);
                throw e;
            });
    }
    return uploadJSONFile(dataToDoc)
        .then((uploadId) => {
            const assetInfo = mapSceneToAsset(scene);
            assetInfo.initialRevision = {
                uri: getUrlById(uploadId),
            };
            return makeAuthenticatedServiceRequest({ url, method: 'post', data: assetInfo })
                .then((res) => res.data)
                .catch((e) => {
                    logError(`Failure to import scene! ${parseError(e)}`);
                    throw e;
                });
        })
        .catch((e) => {
            logError(`Failure to Upload JSON File! ${parseError(e)}`);
            throw e;
        });
}

function checkLinkChanges(link, configData, subReferences) {
    const skuChanged = link.referenceId !== configData.sku;
    const skuChangedVersion = link.referenceIdVersion !== configData.skuVersion;
    const subreferenceChanged =
        JSON.stringify(link.subReferenceIds) !== JSON.stringify(subReferences.map((sub) => (sub && sub.id) || null));
    const attributesChanged = JSON.stringify(link.attributes) !== JSON.stringify(configData.fixedAttributesSelection);
    const purposesChanged = JSON.stringify(link.linkPurposes) !== JSON.stringify(configData.scenePurposes);

    return skuChanged || skuChangedVersion || subreferenceChanged || attributesChanged || purposesChanged;
}

export function updateSceneVariationContent({ id, sceneId, configData }) {
    const url = `${SCENE_ENDPOINT}/v3/assets/${id}/content`;
    const dataToDoc = mapDataToDoc(sceneId, configData);

    return makeAuthenticatedServiceRequest({ url, method: 'post', data: dataToDoc }).catch((e) => {
        logError(`Failure to import scene! ${parseError(e)}`);
        throw e;
    });
}

export function updateLinkVariation({ assetId, linkId, variableConfigurationId, configData, subReferences, tenant }) {
    const linkData = {
        variableConfigurationId,
        referenceId: configData.sku,
        referenceIdVersion: configData.skuVersion,
        subReferenceIds: subReferences.map((ref) => ref && ref.id).filter(Boolean),
        attributes: configData.fixedAttributesSelection,
        linkPurposes: mapPurposes(configData.scenePurposes),
        tags: configData.tags,
        tenant,
        view: configData.view,
    };

    return updateLink({ linkInput: linkData, assetId, linkId }).catch((e) => {
        logError(`Failure to update link! ${parseError(e)}`);
        throw e;
    });
}

export function updateSceneVariation({ link, scene, configData, subReferences, tenant }) {
    const linkHasChanges = checkLinkChanges(link, configData, subReferences);

    if (linkHasChanges) {
        return updateLinkVariation({
            assetId: scene.id,
            linkId: link.id,
            configData,
            tenant,
            subReferences,
            variableConfigurationId: configData.sceneVariableId,
        }).then(() =>
            saveSceneVariationAsset({ variableConfigurationId: link.variableConfigurationId, scene, configData }),
        );
    }

    return saveSceneVariationAsset({ variableConfigurationId: link.variableConfigurationId, scene, configData });
}

export function loadSceneVariations({ link }) {
    if (link) {
        return getLinkData(link);
    }

    return {};
}

export function realizeScene({ variationId, purposes, attributes, width, sku, skuVersion }) {
    const data = {
        VariableAssetUri: `${SCENE_ENDPOINT}/v3/assets/${variationId}/content`,
        attributes,
        LinkPurposes: purposes,
    };
    const url = `${SCENE_ENDPOINT}/v3/realize?input=${encodeURIComponent(JSON.stringify(data))}`;

    return makeAuthenticatedServiceRequest({ url, method: 'post' })
        .then((res) => {
            // build preview URLS?
            if (res.data.Results) {
                return res.data.Results.map((scene) =>
                    generatePreviewUsingSku({
                        url: scene.VariableAssetUrl,
                        size: width,
                        width,
                        useWidth: true,
                        sku,
                        skuVersion,
                        variables: attributes,
                    }),
                );
            }
            return [];
        })
        .catch((e) => {
            logError(`Failure to preview scene! ${parseError(e)}`);
            throw e;
        });
}
