/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAttribute } from '@cimpress-technology/generic-selector/lib/Models/StateDescriptor';
import { APIError, CroppingPointType, PointType } from 'src/types/index';

export function timeoutPromise(time: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

export function expandPoints(points: PointType[]) {
    if (points.length === 2) {
        return [points[0], { x: points[1].x, y: points[0].y }, points[1], { x: points[0].x, y: points[1].y }];
    }
    return points;
}

export function isPointInsidePolygon(x: number, y: number, points: PointType[]) {
    const ePoints = expandPoints(points);

    let inside = false;
    for (let i = 0, j = ePoints.length - 1; i < ePoints.length; j = i++) {
        const xi = parseFloat(ePoints[i].x + '');
        const yi = parseFloat(ePoints[i].y + '');
        const xj = parseFloat(ePoints[j].x + '');
        const yj = parseFloat(ePoints[j].y + '');

        const intersects = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersects) {
            inside = !inside;
        }
    }

    return inside;
}

// Very fast, returns a uniq array
export function uniq(a: any[]) {
    const seen: Record<string, number> = {};
    const out: any[] = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
        const item = a[i];
        if (seen[item] !== 1) {
            seen[item] = 1;
            out[j++] = item;
        }
    }
    return out;
}

// This keeps the last occurance of an element in an array, instead of the first. Matters only for ordering, so use uniq in most situations.
export function revUniq(a: any[]) {
    const aDup = a.slice();
    aDup.reverse(); // destructive
    return uniq(aDup).reverse();
}

// Return a reference to a new array, with the given value removed from the array.
export function removeFromArray(arr: any[], value: number) {
    const newArray = arr.slice();
    const index = newArray.indexOf(value);
    if (index < 0) {
        return newArray;
    }
    newArray.splice(index, 1);
    return newArray;
}

// This only works on shallow objects
export function objectsAreEqual(a: Record<string, any>, b: Record<string, any>) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    return aProps.every((key) => a[key] === b[key]);
}

export function parseError(e: APIError) {
    return `${e} ${e.response && e.response.data && e.response.data.message}`;
}

export function calculateCanvasScale(
    scale: number,
    container: HTMLElement,
    image: { width?: number; height?: number },
) {
    if (!image || !image.width || !image.height) {
        return scale;
    }
    const containerRect = container.getBoundingClientRect();
    const widthScale = containerRect.width / image.width;
    const heightScale = containerRect.height / image.height;
    const baseScale = widthScale < heightScale ? widthScale : heightScale;
    return baseScale * scale;
}

export function getHexColor(color: string) {
    if (color.length > 7) {
        return color.substring(0, 7);
    }

    return color;
}

export function toSelectOption(input: any[] | string, value?: any) {
    if (Array.isArray(input)) {
        if (input.length === 0) return [];

        return input.map((val) => ({ label: val, value: val }));
    }
    return { label: input, value: value || input };
}

export function translateCanvasPoints({
    points,
    scale,
    offset,
    target,
}: {
    points: PointType[];
    scale: number;
    offset: PointType;
    target: { scale: number; offset: PointType };
}) {
    if (points.length === 0 || !points[0].x || !scale || !offset || !target) return points;

    return points.map((point) => {
        const x = point.x / (scale / target.scale) + target.offset.x - offset.x / (scale / target.scale);
        const y = point.y / (scale / target.scale) + target.offset.y - offset.y / (scale / target.scale);
        return { x, y };
    });
}

export function fromCroppingToPoints(rule: CroppingPointType) {
    return [
        { x: rule.x0, y: rule.y0 },
        { x: rule.x1, y: rule.y1 },
    ];
}

export function fromPointsToCropping(points: PointType[], rule: CroppingPointType) {
    if (points.length === 2) {
        return { ...rule, x0: points[0].x || 0, y0: points[0].y || 0, x1: points[1].x || 0, y1: points[1].y || 0 };
    }

    return rule;
}

export function randomCharacters(number: number) {
    const randomchar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 1; i < number; i++) {
        captcha += randomchar.charAt(Math.random() * randomchar.length);
    }

    return captcha;
}

export function AttributesToObject(attributes: IAttribute[] | undefined) {
    if (attributes === undefined) return {};

    const newData: Record<string, string> = {};
    attributes.forEach((i) => (newData[i.key || ''] = i.resolvedValue || ''));
    return newData;
}
