import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { loadVersions, fetchProduct, retrieveRulesForProduct, translateSku } from 'src/components/skus/saga';
import { deleteLink, generateLinkUri, getLinksForAsset } from 'src/services/linkClient';
import { getSceneId } from 'src/selectors/pages/editor';
import { getSceneSize } from 'src/selectors/layers';
import {
    alertDeleteSceneVariationFailed,
    alertDeleteSceneVariationSuccess,
    ParseCroppingCoordinatesFailed,
} from 'src/components/alert/slice';
import { fromCroppingToPoints, translateCanvasPoints } from 'src/util/misc';
import { getAllSkuData } from 'src/selectors/skus';
import { logError } from 'src/loggingManager';
import { deleteAsset } from 'src/services/assetsClient';
import getLinks from './selectors/getSceneLinks';
import getDataLinks from './selectors/getSceneDataLinks';
import { getLinkData, mapPurposes, realizeScene } from './service';
import getSceneVariation from './selectors/getSceneVariation';
import {
    retrieveVersionsForSku,
    retrieveProductDetails,
    setMCPSkus,
    loadLink,
    setCurrentLink,
    setCreateMode,
    setLinks,
    loadPreviews,
    loadedPreviews,
    setCroppingArea,
    endCropping,
    saveCropping,
    resetCroppingArea,
    deleteSceneVariationSuccess,
    deleteSceneVariation,
    updateConstraint,
    updateSceneVariableRule,
} from './slice';

function* getVersionsForASku({ payload }) {
    yield call(loadVersions, { payload });
}

function* loadProductDetails({ payload }) {
    if (payload.sku) {
        const mcp = yield call(translateSku, payload);
        const payloadToRetrieve = { ...payload };
        if (mcp.platformId !== payload.sku) {
            yield put(setMCPSkus({ cimSku: mcp.platformId, cimSkuVersion: mcp.platformIdVersion }));
            payloadToRetrieve.sku = mcp.platformId;
            payloadToRetrieve.skuVersion = mcp.platformIdVersion;
        }
        yield call(fetchProduct, { payload: { ...payloadToRetrieve, throwAgain: true } });
        yield call(retrieveRulesForProduct, { payload: payloadToRetrieve });
    }
}

function* loadSceneConfiguration({ payload }) {
    if (payload) {
        const links = yield select(getLinks);
        const link = links.find((l) => l.linkId === payload);
        const cacheLinks = yield select(getDataLinks);
        if (link) {
            const cache = cacheLinks.find((l) => l.sceneLinkId === link.linkId);
            let data;
            if (cache) {
                data = cache;
            } else {
                data = yield call(getLinkData, link);
            }
            const skus = yield select(getAllSkuData);
            if (!skus[`${data.sku}`]) {
                yield put(retrieveVersionsForSku(data.sku));
            }
            if (!skus[`${data.sku}-${data.skuVersion}`]) {
                yield put(retrieveProductDetails({ sku: data.sku, skuVersion: data.skuVersion }));
            }

            yield put(setCurrentLink(data));
        } else {
            yield put(loadLink(undefined));
        }
    }
}

function* loadLinksForScene({ payload }) {
    if (!payload) {
        const sceneId = yield select(getSceneId);
        if (sceneId) {
            const links = yield call(getLinksForAsset, { assetId: sceneId });
            const sceneVariationConfigs = links.filter((link) => !!link.variableConfigurationId);
            yield put(setLinks({ links: sceneVariationConfigs }));
        }
    }
}

function* loadPreviewScenes({ payload }) {
    try {
        if (payload.attributes && payload.purposes) {
            const { sku, skuVersion, sceneVariableId, fixedAttributesSelection } = yield select(getSceneVariation);
            const attributes = Object.assign({}, fixedAttributesSelection, payload.attributes);
            const { width } = yield select(getSceneSize);
            const images = yield call(realizeScene, {
                variationId: sceneVariableId,
                attributes,
                purposes: mapPurposes(payload.purposes),
                sku,
                skuVersion,
                width,
            });
            yield put(loadedPreviews({ images }));
        } else {
            yield put(
                loadedPreviews({
                    images: [],
                    error: 'Parameter Attribute is required and It was not found, please try again.',
                }),
            );
        }
    } catch (e) {
        yield put(loadedPreviews({ images: [], error: e.message }));
    }
}

function* parseCroppingToApp() {
    const sceneConfiguration = yield select(getSceneVariation);
    const { cropping, sceneVariables } = sceneConfiguration;

    const { purpose, ruleId } = cropping;
    const foundItem = sceneVariables.find((i) => i.purpose === purpose && i.rules.find((r) => r.id === ruleId));
    if (foundItem) {
        const rule = foundItem.rules.find((r) => r.id === ruleId);
        const rulePoints = fromCroppingToPoints(rule);
        const parsedPoints = translateCanvasPoints({
            points: rulePoints,
            scale: 1,
            offset: { x: 0, y: 0 },
            target: {
                scale: cropping.scale,
                offset: cropping.points[0],
            },
        });

        yield put(saveCropping(parsedPoints));
    }
}

function* parseCroppingToSave() {
    const sceneConfiguration = yield select(getSceneVariation);
    const { cropping, sceneVariables } = sceneConfiguration;

    const { purpose, ruleId } = cropping;
    const foundItem = sceneVariables.find((i) => i.purpose === purpose && i.rules.find((r) => r.id === ruleId));
    if (foundItem) {
        const rule = foundItem.rules.find((r) => r.id === ruleId);
        const rulePoints = fromCroppingToPoints(rule);
        const parsedPoints = translateCanvasPoints({
            points: rulePoints,
            offset: cropping.points[0],
            scale: cropping.scale,
            target: {
                scale: 1,
                offset: { x: 0, y: 0 },
            },
        });

        try {
            yield put(saveCropping(parsedPoints));
            yield put(resetCroppingArea());
        } catch (er) {
            yield put(ParseCroppingCoordinatesFailed());
            // eslint-disable-next-line no-console
            console.error(er);
        }
    }
}

function* deleteScene({ payload }) {
    const { sceneVariableId, sceneId, linkId } = payload;
    try {
        if (linkId) {
            const uri = generateLinkUri({ assetId: sceneId, linkId });
            yield call(deleteLink, { linkUri: uri });
            yield call(deleteAsset, { assetId: sceneVariableId });
            yield put(alertDeleteSceneVariationSuccess());
            yield put(deleteSceneVariationSuccess(sceneVariableId));
        }
    } catch (error) {
        yield put(alertDeleteSceneVariationFailed());
        logError(`failed deleting link: ${error}`);
    }
}

function* constraintCropping({ payload }) {
    const sceneConfiguration = yield select(getSceneVariation);
    const { cropping, sceneVariables } = sceneConfiguration;

    if (payload.field === 'enable' && payload.value === true) {
        const { purpose, ruleId } = cropping;
        const foundItem = sceneVariables.find((i) => i.purpose === purpose && i.rules.find((r) => r.id === ruleId));
        if (foundItem) {
            const rule = foundItem.rules.find((r) => r.id === ruleId);
            const rulePoints = fromCroppingToPoints(rule);
            const constraintFactor = cropping.constraint.width / cropping.constraint.height;
            const height = (rulePoints[1].x - rulePoints[0].x) / constraintFactor;
            const constraintHeight = rulePoints[0].y + height;

            try {
                yield put(updateSceneVariableRule({ purpose, ruleId, type: 'y1', value: constraintHeight }));
            } catch (er) {
                console.error(er);
            }
        }
    }
}

export default function* sceneConfigurationSagas() {
    return yield all([
        yield takeEvery(retrieveVersionsForSku, getVersionsForASku),
        yield takeEvery(retrieveProductDetails, loadProductDetails),
        yield takeEvery(loadLink, loadSceneConfiguration),
        yield takeEvery(setCreateMode, loadLinksForScene),
        yield takeEvery(loadPreviews, loadPreviewScenes),
        yield takeEvery(setCroppingArea, parseCroppingToApp),
        yield takeEvery(endCropping, parseCroppingToSave),
        yield takeEvery(deleteSceneVariation, deleteScene),
        yield takeEvery(updateConstraint, constraintCropping),
    ]);
}
