export const getDefaultForAttributeValues = (options, defaultValue) => {
    const defaultValues = {};

    options.forEach((eachOption) => {
        if (eachOption.value) {
            defaultValues[eachOption.value] = defaultValue;
        } else if (eachOption.range) {
            const range = eachOption.range;
            // if the range is only 5 or less values use them as if they are string values
            // ignore ranges with more than 5 values for now
            // this logic is need as deco location attribute could use range value 0 - 1 as its values
            if ((range.maximum - range.minimum) / range.increment <= 5) {
                for (let i = parseInt(range.minimum); i <= parseInt(range.maximum); i += parseInt(range.increment)) {
                    defaultValues[i.toString()] = defaultValue;
                }
            }
        }
    });

    return defaultValues;
};
