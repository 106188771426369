import { call, put, all, select } from 'redux-saga/effects';

import { getProductVersions, retrievePlatformId, retrieveProductData } from 'src/services/productClient';
import { retrieveAllAndRequiredVariables } from 'src/services/attributeClient';
import { getSkuById } from 'src/selectors/skus';
import { logError } from 'src/loggingManager';
import {
    fetchSku,
    fetchSkuSuccess,
    fetchSkuFailed,
    retrieveRules,
    retrieveRulesSuccess,
    retrieveRulesFailed,
    fetchVersionsForSkuSuccess,
    fetchVersions,
    fetchVersionsForSkuFailed,
} from './slice';

/**
 * @param {string} sku
 * @returns {object { platformId, platformIdVersion }} - An object containing the platform and merchant id.
 */
export function* translateSku({ sku, skuVersion, attributes }) {
    if (sku.startsWith('PRD')) {
        try {
            const { merchantSku, merchantVersion, merchantVariables } = yield call(retrievePlatformId, {
                sku,
                skuVersion,
                attributes,
            });

            return {
                platformId: merchantSku,
                platformIdVersion: merchantVersion,
                platformIdAttributes: merchantVariables,
            };
        } catch (error) {
            logError(`Failed to retrieve the VP platform id: ${sku} ${error}`);
            throw error;
        }
    }

    return { platformId: sku, merchantId: sku };
}

/**
 * @param {string} payload.sku
 * @param {string} [payload.throwAgain]
 */
export function* fetchProduct({ payload }) {
    const { sku, skuVersion, throwAgain } = payload;
    let product = yield select(getSkuById, sku);
    if (product) {
        if (product.doesNotExist && throwAgain) {
            throw Error('Sku has been searched for already and does not exist!');
        }
    }

    yield put(fetchSku({ sku, skuVersion }));
    try {
        product = yield call(retrieveProductData, { sku, skuVersion });
        yield put(
            fetchSkuSuccess({
                sku,
                skuVersion,
                productName: product.name,
                lastModified: product.modified,
                options: product.options,
            }),
        );
    } catch (error) {
        yield put(fetchSkuFailed({ sku, skuVersion }));
        if (throwAgain) {
            throw error;
        }
    }
}

/**
 * @param {string} payload Sku product code
 */
export function* loadVersions({ payload }) {
    const product = yield select(getSkuById, payload);

    if (product) {
        if (product.doesNotExist) {
            throw Error('Sku has been searched for already and does not exist!');
        }
        return;
    }

    try {
        yield put(fetchVersions({ sku: payload }));
        const versions = yield call(getProductVersions, payload);
        yield put(fetchVersionsForSkuSuccess({ versions, sku: payload }));
    } catch (error) {
        try {
            const isV1Product = yield call(retrieveProductData, { sku: payload });
            if (isV1Product.name) {
                yield put(fetchVersionsForSkuSuccess({ versions: [], sku: payload, isV1Product: true }));
            }
        } catch {
            yield put(fetchVersionsForSkuFailed(payload));
        }
    }
}

/**
 * @param {string} payload.skus
 */
export function* fetchProducts({ payload }) {
    yield all(payload.skus.map((sku) => call(fetchProduct, { payload: { sku } })));
}

/**
 * @param {string} payload.sku
 */
export function* retrieveRulesForProduct({ payload }) {
    const { sku, skuVersion } = payload;
    const skuKey = skuVersion ? `${sku}-${skuVersion}` : sku;
    const skuData = yield select(getSkuById, skuKey);
    if (skuData && skuData.rulesLoaded) {
        return;
    }

    yield put(retrieveRules({ sku, skuVersion }));
    try {
        const { ruleSet, requiredVariables, merchandisingOnly } = yield call(retrieveAllAndRequiredVariables, sku);
        yield put(retrieveRulesSuccess({ sku, skuVersion, requiredVariables, ruleSet, merchandisingOnly }));
    } catch (error) {
        logError(`rules retrieval error ${sku} v${skuVersion} ${error}`);
        yield put(retrieveRulesFailed({ sku, skuVersion }));
    }
}
