import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion, Select } from '@cimpress/react-components';
import { toSelectOption } from 'src/util/misc';

const EachSurfaceVariable = (props) => {
    const { configuration, removeConfiguration, updateConfiguration, documents } = props;

    const deleteConfiguration = () => removeConfiguration();

    const onConfigurationChangeHandler = (key, value) => {
        updateConfiguration({ ...configuration, [key]: value });
    };

    return (
        <Accordion
            className='product-variable-options-list'
            defaultOpen={true}
            title={
                <div className='layers-header'>
                    <div className='layers-header__text truncate_label' title={configuration.variableAttribute}>
                        {configuration.variableAttribute}
                    </div>
                    <button onClick={deleteConfiguration}>
                        <img className='iconLg' src='https://static.ux.cimpress.io/assets/icons/bin-1-f.svg' />
                    </button>
                </div>
            }>
            <div className='accordion-body-wrapper'>
                <p>
                    <b>Select All Customizable Area That Apply</b>
                </p>
                <Select
                    options={toSelectOption(documents.map((doc) => doc.id))}
                    label='Select Document(s)'
                    value={configuration.documents?.length > 0 ? toSelectOption(configuration.documents) : undefined}
                    onChange={(chosenOption) =>
                        onConfigurationChangeHandler(
                            'documents',
                            chosenOption?.map((selected) => selected.value),
                        )
                    }
                    menuPortalTarget={document.body}
                    isMulti={true}
                />
            </div>
        </Accordion>
    );
};

EachSurfaceVariable.propTypes = {
    configuration: PropTypes.shape({
        variableAttribute: PropTypes.string,
        documents: PropTypes.arrayOf(PropTypes.string),
        values: PropTypes.arrayOf(PropTypes.any),
    }),
    updateConfiguration: PropTypes.func.isRequired,
    removeConfiguration: PropTypes.func.isRequired,
    documents: PropTypes.arrayOf(PropTypes.any),
};

export default connect()(EachSurfaceVariable);
