import React, { useCallback, useMemo, useRef, useState } from 'react';
import auth from 'src/auth';
import { connect } from 'react-redux';
import { getAllBackgrounds, getAllDocuments, getAllOverlays } from 'src/selectors/layers';
import { Button, Accordion, Select, TextField } from '@cimpress/react-components';
import {
    AttributeChangeType,
    GenericSelectionAttribute,
    GenericVariableConfiguration,
    IProductAttributesProps,
    ProductAttributesSelector,
    parseChangesToVariableConfiguration,
} from '@rendering/components';
import { ENTER } from 'src/util/keycodes';
import getMerchantSettings from 'src/selectors/getSettings';
import ProductVariables from './ProductVariables';
import SceneVariables from './SceneVariables';
import { MERCHANDISING, PRODUCT_VARIABLE_TYPES, PURPOSES_OPTIONS, SCENE_VARIABLE_TYPES } from './constants';
import { updateScenePurpose, updateFixedAttributesSelection, removeTag, setTags } from './slice';
import getSceneVariation from './selectors/getSceneVariation';
import PreviewScene from './PreviewScene';
import SceneTags from '../save/sceneTags/sceneTags';
import ViewOptions from './ViewOptions';
import { OverlaySliceState } from '../overlays/slice';
import { DocumentSliceState } from '../documents/slice';
import { RootState } from 'src/store';
import { BackgroundSliceState } from '../backgrounds/slice';
import { toSelectOption } from 'src/util/misc';
import SurfaceVariables from './SurfaceVariables';
import GenericSelector from '@cimpress-technology/generic-selector';

const options: IProductAttributesProps['options'] = {
    selector: {
        errorAlertConfiguration: { showErrorAlert: false },
        isColorSwatch: true,
    },
    hideAll: true,
    tooltip: {
        show: true,
    },
};

const setInitialValues = (attributes: GenericVariableConfiguration[]) => {
    const configuration: Record<string, GenericSelectionAttribute> = {};
    attributes?.forEach((item) => {
        if (item.key) {
            configuration[item.key] = {
                initialSelection: item.resolvedValue || '',
                isHidden: !item.isDisplayed,
            };
        }
    });
    return configuration;
};

const AttributesSelection = ({
    updateFixedAttributesSelection,
    fixedAttributesSelection,
    productConfigurations,
    sceneVariables,
    scenePurposes,
    updateScenePurpose,
    userSettings,
    productDetails,
    sku,
    skuVersion,
    tags,
    addTag,
    removeTag,
    overlays,
    backgroundLayers,
    documentMasks,
}: IAttributesSelection) => {
    const ref = useRef<GenericSelector | null>(null);
    const [genericOptions, setGenericOptions] = useState(options);
    const selectedAttributes = useMemo(() => Object.keys(fixedAttributesSelection), [fixedAttributesSelection]);

    const assets = useMemo(
        () => [
            ...overlays,
            ...backgroundLayers,
            ...documentMasks
                .filter((each) => !!each.maskUrl)
                .map((each) => ({ id: each.maskName, name: each.maskName })),
        ],
        [overlays, backgroundLayers, documentMasks],
    );

    const [currentSubpurpose, setCurrentSubPurpose] = useState('');

    const handleAttributeChanges = useCallback(
        (changes: AttributeChangeType) => {
            const allAttributes = parseChangesToVariableConfiguration(changes.attributes);

            const newConfiguration = setInitialValues(allAttributes);
            setGenericOptions((current) =>
                Object.assign({}, current, {
                    selector: { ...current.selector, attributeConfigurations: newConfiguration },
                }),
            );
            const updates: Record<string, any> = {};
            allAttributes
                .filter((i) => i.isDisplayed)
                .map((item) => {
                    updates[item.key] = item.resolvedValue;
                }),
                updateFixedAttributesSelection(updates);
        },
        [updateFixedAttributesSelection],
    );

    const getAssetOptions = useCallback(
        (optionsFor: string) => {
            let assetsAlreadyUsed = productConfigurations.filter(
                ({ variableOption }) => variableOption === PRODUCT_VARIABLE_TYPES.product,
            );
            assetsAlreadyUsed = assetsAlreadyUsed.filter((each) => !!each.layer).map((each) => each.layer.value);
            if (optionsFor === PRODUCT_VARIABLE_TYPES.product) {
                const sceneVariablesAssetsUsed = sceneVariables.reduce((acc, current) => {
                    const assetChangeTypes = current.rules.filter(
                        (each: any) => each.type === SCENE_VARIABLE_TYPES['Asset Change'] && !!each.asset,
                    );
                    return acc.concat(assetChangeTypes.map((each: any) => each.asset.value));
                }, []);
                assetsAlreadyUsed = assetsAlreadyUsed.concat(sceneVariablesAssetsUsed);
            }

            return assets
                .filter((each) => !assetsAlreadyUsed.find((i) => each.id == i.id))
                .map((each) => ({
                    value: each.id,
                    label: each.name,
                }));
        },
        [assets, productConfigurations, sceneVariables],
    );

    const updateCurrentSubpurpose = (
        event: React.ChangeEvent<HTMLInputElement> & {
            isValid: boolean;
        },
    ) => {
        setCurrentSubPurpose(event.target.value);
    };

    const addCustomSubpurposes = () => {
        const newSubpurposes = currentSubpurpose
            .split(',')
            .filter((tag) => tag)
            .map((tag) => tag.trim());
        updateScenePurpose([
            ...scenePurposes,
            ...newSubpurposes.map(
                (each) => toSelectOption(`${MERCHANDISING.value} ${each}`) as { label: string; value: string },
            ),
        ]);
        setCurrentSubPurpose('');
    };

    const purposeOptions = useMemo(() => {
        const userSurpurposes = userSettings.merchandisingSubPurposes.map((i) => ({
            label: `${MERCHANDISING.label} ${i}`,
            value: `${MERCHANDISING.value}:${i}`,
        }));
        return [...PURPOSES_OPTIONS, ...userSurpurposes].filter((opt) => !scenePurposes.find((item) => item === opt));
    }, [scenePurposes, userSettings.merchandisingSubPurposes]);

    const productVariableAssetOptions = useMemo(
        () => getAssetOptions(PRODUCT_VARIABLE_TYPES.product),
        [getAssetOptions],
    );

    // get attribute options from generic selector
    const attributesOptions = useMemo(() => {
        const attributeSelecorState = ref.current?.state;

        if (attributeSelecorState?.product) {
            return attributeSelecorState.product?.options;
        }

        // if product is not defined try getting it from serialized data
        const options = ref.current?.state.serializedData?.getAttributeInformations() ?? {};
        let optionList = Object.entries(options);

        // we only care about the 'option' type attributes not the 'property' type
        // and also the remaining attributes that haven't been selected
        const selected = selectedAttributes.map((attr) => attr.toLocaleLowerCase());
        optionList = optionList.filter(
            ([key, value]) => !selected.includes(key.toLocaleLowerCase()) && value.buckets.includes('option'),
        );

        // translating data into same format as we would get from state.product.options
        return optionList.map(([key, value]) => {
            let type = '';
            let values: any[] = [];

            switch (value.values[0].type) {
                case 'stringLiteral':
                    type = 'string';
                    values = value.values.map((val) => ({ type: 'value', value: val.stringLiteral }));
                    break;
                case 'numberLiteral':
                    type = 'string';
                    values = value.values.map((val) => ({ type: 'value', value: val.numberLiteral }));
                    break;
                case 'range':
                    type = 'range';
                    values = value.values.map((val) => ({ type: 'range', range: val.range }));
                    break;
            }

            // capital letter of each word as attribute key values are all lowercase
            // when it comes from serializedData.getAttributeInformations()
            const name = key
                .split(' ')
                .map((word) => word[0].toLocaleUpperCase() + word.substring(1))
                .join(' ');

            return { name, type, values };
        });
    }, [ref.current?.state.serializedData, selectedAttributes]);

    const details = useMemo(
        () =>
            scenePurposes.length ? (
                <>
                    <ProductVariables
                        attributesOptions={attributesOptions}
                        assetOptions={productVariableAssetOptions}
                    />
                    <SceneVariables purposeOptions={scenePurposes} getAssetOptions={getAssetOptions} />
                    <SurfaceVariables attributesOptions={attributesOptions} />
                    <PreviewScene />
                </>
            ) : (
                <></>
            ),
        [scenePurposes, attributesOptions, productVariableAssetOptions, productDetails, getAssetOptions],
    );

    const renderSelector = useMemo(() => {
        const token = auth.getAccessToken() || '';
        return (
            <ProductAttributesSelector
                sku={sku}
                skuVersion={skuVersion}
                variables={fixedAttributesSelection}
                onChange={handleAttributeChanges}
                token={token}
                options={genericOptions}
                ref={ref}
            />
        );
    }, [sku, skuVersion, handleAttributeChanges, genericOptions, fixedAttributesSelection, ref]);

    return (
        <>
            <Accordion title='Link Information' defaultOpen={true}>
                <div className='accordion-body-wrapper'>
                    {renderSelector}
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>View Options</h3>
                        <ViewOptions />
                    </div>
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>Choose scene purposes</h3>
                        <div>
                            <Select
                                label='Select Purpose(s)'
                                isMulti={true}
                                value={scenePurposes}
                                options={purposeOptions}
                                onChange={(chosenOptions) =>
                                    updateScenePurpose((chosenOptions || []).map((each: any) => each))
                                }
                                menuPortalTarget={document.body}
                            />
                            <TextField
                                value={currentSubpurpose}
                                label='Add custom merchandising subpurposes'
                                onChange={updateCurrentSubpurpose}
                                onKeyDown={(e) => {
                                    if (e.keyCode === ENTER) {
                                        addCustomSubpurposes();
                                    }
                                }}
                                rightAddon={
                                    <Button onClick={addCustomSubpurposes} disabled={!currentSubpurpose}>
                                        Add
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <hr style={{ borderTop: '1px solid #ccd5de' }} />
                        <h3 style={{ textAlign: 'center' }}>Tags</h3>
                        <SceneTags
                            tags={tags}
                            predefinedTags={userSettings.tags.default}
                            addTag={addTag}
                            removeTag={removeTag}
                        />
                    </div>
                </div>
            </Accordion>
            {details}
        </>
    );
};

interface IAttributesSelection {
    productDetails: {
        ruleSet: any;
    };
    sku: string;
    skuVersion: number;
    overlays: OverlaySliceState;
    documentMasks: DocumentSliceState;
    backgroundLayers: BackgroundSliceState;
    scenePurposes: { label: string; value: string }[];
    updateScenePurpose: (purposes: { label: string; value: string }[]) => void;
    fixedAttributesSelection: Record<string, any>;
    updateFixedAttributesSelection: (changes: Record<string, any>) => void;
    productConfigurations: Record<string, any>[];
    sceneVariables: Record<string, any>[];
    userSettings: {
        merchandisingSubPurposes: string[];
        tags: {
            default: string[];
            surfacePrefix: string;
        };
    };
    tags: string[];
    addTag: (tags: string[]) => void;
    removeTag: (tag: string) => void;
}

function mapStateToProps(state: RootState) {
    const backgroundLayers = getAllBackgrounds(state);
    const overlays = getAllOverlays(state);
    const documentMasks = getAllDocuments(state);
    const { fixedAttributesSelection, scenePurposes, productConfigurations, sceneVariables, tags } =
        getSceneVariation(state);
    const userSettings = getMerchantSettings(state);

    return {
        overlays,
        documentMasks,
        backgroundLayers,
        scenePurposes,
        fixedAttributesSelection,
        productConfigurations,
        sceneVariables,
        userSettings,
        tags,
    };
}

export default connect(mapStateToProps, {
    updateScenePurpose,
    updateFixedAttributesSelection,
    addTag: setTags,
    removeTag,
})(AttributesSelection);
