import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextField, Button } from '@cimpress/react-components';
import { VersionSelector } from '@rendering/components';

import { ENTER } from 'src/util/keycodes';
import { SurfaceStateType } from 'src/types/surface';

// Statuses
const DEFAULT = 'default';
const NO_SKU = 'noSku';

function getErrorMessage(skuData: SurfaceStateType, status: string) {
    if (status === NO_SKU) {
        return 'Sku required!';
    }
    if (skuData && skuData.doesNotExist) {
        return 'No product with this MCP SKU could be found. SKUs are case sensitive.';
    }
    if (skuData && skuData.noLinks) {
        return 'SKU exists, but no asset links found for the given MCP SKU. Please try another SKU.';
    }
    return '';
}

function getStyle(skuData: SurfaceStateType, errorMessage: string) {
    if (errorMessage) {
        return 'error';
    }
    if (skuData && skuData.rulesLoaded) {
        return 'success';
    }
    return undefined;
}

type SkuInputState = {
    status: string;
    inputValue: string;
    productVersion: number | undefined;
    skuSelected: boolean;
};

const SkuInput: FC<ISkuInput> = ({
    sku,
    skuVersion,
    merchantSku,
    merchantVersion,
    allSkus,
    isReady,
    hasNoVersions,
    required,
    disabled,
    onClearProduct,
    onValidateSku,
    onChangeProduct,
    onChangeVersion,
}) => {
    // TODO: Merchant is always present as it's being default from the SKU, but we keep the same logic
    // of the old component to avoid any problem, we will change this on the next refactor
    const [state, setState] = useState({
        status: DEFAULT,
        inputValue: merchantSku || sku || '',
        skuSelected: false,
        productVersion: merchantVersion || skuVersion,
    });

    const { inputValue, productVersion, status, skuSelected } = state;
    const versions = useMemo(
        () => (allSkus[inputValue] && allSkus[inputValue]?.versions ? allSkus[inputValue].versions : []),
        [allSkus, inputValue],
    );
    const skuData = allSkus[sku];
    const loading = !!skuData && (skuData.fetching || skuData.loadingLinks);
    const mappedVersions = versions.map((v: any) => {
        const statusLabel: Record<string, string> = {
            retired: ' (retired)',
            error: ' (error)',
        };
        const status = v.status?.toLowerCase() || '';
        return {
            value: `${v.version}`,
            label: `${v.version}${v.current ? ' (current)' : ''}${statusLabel[status] ?? ''}`,
            status,
            isCurrent: !!v.current,
        };
    });
    const message = getErrorMessage(skuData, status);
    const style = getStyle(skuData, message);
    const btnText =
        (inputValue && ((productVersion && productVersion >= 0) || (merchantVersion && merchantVersion >= 0))) ||
        hasNoVersions
            ? 'Add'
            : 'Verify';
    const disableInput = skuSelected;

    useEffect(() => {
        setState((cur: SkuInputState) => ({
            ...cur,
            inputValue: merchantSku || sku || '',
            productVersion: merchantVersion || skuVersion,
            skuSelected: isReady,
        }));
    }, [isReady, skuVersion, sku, merchantSku, merchantVersion]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement> & { isValid: boolean }) => {
        const newValue = e.target.value.trim();
        const existingVersions = allSkus[newValue]?.versions ?? [];
        if (existingVersions.length > 0) {
            onValidateSku(newValue);
        }

        setState((cur: SkuInputState) => ({ ...cur, status: DEFAULT, inputValue: newValue }));
    };

    const onClear = () => {
        setState((cur: SkuInputState) => ({
            ...cur,
            status: DEFAULT,
            inputValue: '',
            productVersion: undefined,
            skuSelected: false,
        }));
        onClearProduct();
    };

    const getProductVersions = () => {
        if (!inputValue) {
            setState((cur: SkuInputState) => ({ ...cur, status: NO_SKU }));
            return;
        }
        // If has no versions, mark the current sku as selected.
        if (hasNoVersions) {
            setState((cur: SkuInputState) => ({ ...cur, skuSelected: true }));
        }

        onValidateSku(inputValue);
    };

    const getProductData = () => {
        if (!inputValue) {
            setState((cur: SkuInputState) => ({ ...cur, status: NO_SKU }));
            return;
        }
        setState((cur: SkuInputState) => ({ ...cur, skuSelected: true }));

        onChangeProduct({ sku: inputValue, skuVersion: productVersion });
    };

    const onKeyDown = (e: any) => {
        if (e.keyCode === ENTER) {
            getProductVersions();
        }
    };

    const onSave = () => {
        if (inputValue && ((productVersion && productVersion >= 0) || (merchantVersion && merchantVersion >= 0))) {
            getProductData();
        } else {
            getProductVersions();
        }
    };

    const handleChange = (selected: number) => {
        onChangeVersion(selected);
    };

    return (
        <div className='choose-sku'>
            <div className={'choose-sku__wrapper'}>
                <TextField
                    id='editor-sceneInformation-product-sku-input'
                    className='choose-sku__input'
                    value={inputValue}
                    required={required}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    label='Product SKU'
                    status={style}
                    type='text'
                    disabled={disableInput}
                />
                <VersionSelector
                    versions={mappedVersions}
                    value={productVersion || -1}
                    onVersionSelected={handleChange}
                    disabled={disableInput}
                />
                {!skuSelected && (
                    <Button
                        data-testid='choose-sku-submit-btn'
                        className='choose-sku__submit'
                        disabled={disabled || loading}
                        onClick={onSave}>
                        {btnText}
                    </Button>
                )}
            </div>
            {skuSelected && (
                <Button
                    data-testid='choose-sku-remove-btn'
                    variant='anchor'
                    onClick={onClear}
                    style={{ textDecoration: 'underline', fontWeight: 'bold', margin: '4px' }}>
                    Change Product Info
                </Button>
            )}
            {message && <div className='link__error'>{message}</div>}
        </div>
    );
};

interface ISkuInput {
    required: boolean;
    sku: string;
    skuVersion: number | undefined;
    merchantSku: string;
    merchantVersion: number | undefined;
    allSkus: {
        [key: string]: any;
        fetching: boolean;
        doesNotExist: boolean;
        noLinks: boolean;
        loadingLinks?: boolean;
        productName?: string;
        versions?: {
            productId: string;
            version: number;
            status: string;
            current: boolean;
        }[];
    };
    onChangeProduct: (payload: { sku: string; skuVersion: number | undefined }) => void;
    onChangeVersion: (version: number) => void;
    onClearProduct: (sku?: string) => void;
    onValidateSku: (sku: string) => void;
    disabled: boolean;
    isReady: boolean;
    hasNoVersions: boolean;
}

export default SkuInput;
