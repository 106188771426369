import { all, call, put, takeEvery, select, race, take } from 'redux-saga/effects';

import { uploadFailed, backgroundLowQuality } from 'src/components/alert/slice';
import { isMultiBackground, getSceneSize, getSelectedBackgroundId, getAllBackgrounds } from 'src/selectors/layers';
import { getRegionSetting } from 'src/selectors';
import { NORMAL } from 'src/models/blendingModes';
import { addDocument } from 'src/pages/editor/components/documents/slice';
import { uploadImageAndGetUrl, getImageSize, toSafeUploadName, isPSD, dataURLtoFile } from 'src/util/upload';
import { logError } from 'src/loggingManager';

import {
    uploadBackgrounds,
    removeBackground,
    uploadAndReplaceBackground,
    backgroundUploaded,
    replaceBackgroundFailed,
    selectBackground,
    acceptResize,
    declineResize,
    cancelResizeModalUpload,
    showResizeModal,
    updateBackgroundRemovalModal,
    updateBackgroundById,
    updateBackgroundRemovalSuccess,
    updateBackgroundRemovalFailed,
    updateAssetRemovalModal,
    updateAssetRemovalSuccess,
    updateAssetRemovalFailed,
    loadPSD,
    importPSDFinish,
    importPSDStart,
} from './slice';
import { getSelectedAssetRemoval, getSelectedBackgroundRemoval } from './selectors';
import { updateConfiguration } from '../SceneVariation/slice';
import getSceneVariation from '../SceneVariation/selectors/getSceneVariation';
import { getLayersPSD, getResolutionPSD } from 'src/import/psd/selectors';

const BACKGROUND_MIN_WARNING_THRESHOLD = 700;
const BACKGROUND_WARNING_THRESHOLD = 2000;
const BACKGROUND_MAX_THRESHOLD = 6000;

function imageOverMaxDimension(height, width, maxDimension) {
    return width > maxDimension || height > maxDimension;
}
function imageOverScene(height, width, scene) {
    return scene.width > 0 && scene.height > 0 && (width !== scene.width || height !== scene.height);
}
function calculateResizedBackgroundDimensions(height, width, maxDimension) {
    let newHeight = height;
    let newWidth = width;
    if (height > width && height > maxDimension) {
        newHeight = maxDimension;
        newWidth = Math.round((maxDimension / height) * width);
    } else if (width > height && width > maxDimension) {
        newWidth = maxDimension;
        newHeight = Math.round((maxDimension / width) * height);
    } else if (height === width && height > maxDimension) {
        newHeight = maxDimension;
        newWidth = maxDimension;
    }

    return { height: newHeight, width: newWidth };
}

function* checkImageSize(height, width, sceneSize) {
    let resizeImage = false;
    let resizeToScene = imageOverScene(height, width, sceneSize);

    if (imageOverMaxDimension(height, width, BACKGROUND_WARNING_THRESHOLD) || resizeToScene) {
        if (imageOverMaxDimension(height, width, BACKGROUND_MAX_THRESHOLD)) {
            return [true, resizeToScene];
        }
        yield put(showResizeModal(resizeToScene ? 'scene' : 'large'));
        const { acceptedResize, declinedResize, cancel } = yield race({
            acceptedResize: take(acceptResize),
            declinedResize: take(declineResize),
            cancel: take(cancelResizeModalUpload),
        });

        if (cancel) {
            return [null, null];
        }

        if (acceptedResize && !resizeToScene) {
            resizeImage = true;
        } else if (declinedResize) {
            resizeImage = false;
            resizeToScene = false;
        }
    } else if (width < BACKGROUND_MIN_WARNING_THRESHOLD || height < BACKGROUND_MIN_WARNING_THRESHOLD) {
        yield put(backgroundLowQuality());
    }

    return [resizeImage, resizeToScene];
}

function* uploadBackground(file, id, _verifySize, isFromPSD) {
    let { height, width } = yield call(getImageSize, file);
    const sceneSize = yield select(getSceneSize);
    const psdResolution = isFromPSD;
    let url, resizeImage, resizeScene;

    if (!psdResolution) {
        const [shouldResizeImage, shouldResizeToScene] = yield checkImageSize(height, width, sceneSize);
        resizeImage = shouldResizeImage;
        resizeScene = shouldResizeToScene;

        if (resizeImage === null) {
            // "Cancel" was clicked
            return null;
        }
    }

    try {
        const region = yield select(getRegionSetting);
        let resizeParams;

        if (resizeImage) {
            resizeParams = calculateResizedBackgroundDimensions(height, width, BACKGROUND_WARNING_THRESHOLD);
            height = resizeParams.height;
            width = resizeParams.width;
        }
        if (psdResolution || resizeScene) {
            resizeParams = {
                height: sceneSize.height > 0 ? sceneSize.height : psdResolution.height,
                width: sceneSize.width > 0 ? sceneSize.width : psdResolution.width,
            };
            width = resizeParams.width;
            height = resizeParams.height;
        }

        url = yield call(uploadImageAndGetUrl, file, region, resizeParams);
    } catch (error) {
        yield put(uploadFailed(`Background: ${error}`));
        throw error;
    }

    yield put(
        backgroundUploaded({
            id,
            url,
            width,
            height,
            name: file.name,
            mode: NORMAL,
        }),
    );

    return { width, height };
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 * @param {boolean} payload.isFirstUpload
 */
function* addBackground({ file, id, isFirstUpload, isFromPSD }) {
    try {
        Object.defineProperty(file, 'name', {
            writable: true,
            value: toSafeUploadName(file.name),
        });
        if (isPSD(file)) {
            yield put(removeBackground(id));
            yield put(loadPSD(file));
        } else {
            const { width, height } = yield uploadBackground(file, id, true, isFromPSD);
            if (isFirstUpload) {
                yield put(addDocument({ width, height, id: 'Document 1' }));
            }
        }
    } catch (error) {
        logError(
            `unable to add background, there was an error while uploading/adding: ${error} ${file.name} ${file.size} ${file.type} firstUpload?: ${!isFirstUpload}`,
        );
        yield put(removeBackground(id));
    }
}

/**
 * @param {[UploadedBackground]} payload
 * @param {File} UploadedBackground.file
 * @param {string} UploadedBackground.id
 * @param {boolean} UploadedBackground.isFirstUpload
 */
function* addBackgrounds({ payload }) {
    // TODO: Allow uploading more than one psd at a time
    return yield all(payload.map((background) => call(addBackground, background)));
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 */
function* replaceBackground({ payload }) {
    const { file, id } = payload;
    const verifySize = yield select(isMultiBackground);
    try {
        yield uploadBackground(file, id, verifySize);
    } catch (error) {
        logError(
            `unable to replace background, there was an error while uploading/updating: ${error} ${file.name} ${file.size} ${file.type}`,
        );
        yield put(replaceBackgroundFailed(id));
    }
}

/**
 * @param {string} payload
 */
function* updateBackground({ payload }) {
    const bkgId = yield select(getSelectedBackgroundRemoval);
    try {
        yield put(updateBackgroundById({ id: bkgId, url: payload }));
        yield put(updateBackgroundRemovalSuccess());
    } catch (error) {
        logError(
            `unable to replace background, there was an error while uploading/updating: BackgroundID ${bkgId},  ${payload}`,
        );
        yield put(updateBackgroundRemovalFailed());
    }
}

function fromLayerToFile(layer, isFirstUpload) {
    return {
        id: layer.id,
        file: dataURLtoFile(layer.imageUrl, `${layer.name}.jpeg`),
        isFirstUpload,
    };
}

/**
 *
 * @param { import('../layer/layerTypes').LayerType[] } payload
 */
function* uploadPSDAssets() {
    const layers = yield select(getLayersPSD);
    const resolution = yield select(getResolutionPSD);
    const backgrounds = yield select(getAllBackgrounds);
    const isFirstUpload = backgrounds.length === 0;
    const files = layers.map((layer, index) => {
        const file = fromLayerToFile(layer, index === 0 ? isFirstUpload : false);
        file.isFromPSD = resolution;
        return file;
    });
    yield put(uploadBackgrounds(files));
    yield put(importPSDFinish());
}

/**
 * @param {string} payload
 */
function* updateAsset({ payload }) {
    const bkgId = yield select(getSelectedAssetRemoval);
    const data = bkgId.split(';');
    const sceneConfig = yield select(getSceneVariation);
    if (data.length === 3 && sceneConfig.productConfigurations.length > 0) {
        const variableIndex = sceneConfig.productConfigurations.findIndex(
            (item) => item.variableOption === data[0] && item.variableAttribute === data[1],
        );
        const newConfig = sceneConfig.productConfigurations[variableIndex];

        try {
            yield put(
                updateConfiguration({
                    key: 'productConfigurations',
                    newConfiguration: {
                        ...newConfig,
                        values: {
                            ...newConfig.values,
                            [data[2]]: {
                                ...newConfig.values[data[2]],
                                value: {
                                    ...newConfig.values[data[2]].value,
                                    imageUrl: payload,
                                },
                            },
                        },
                    },
                    index: variableIndex,
                }),
            );
            // update scene variation with new url
            yield put(updateAssetRemovalSuccess());
        } catch (error) {
            logError(
                `unable to replace background, there was an error while uploading/updating: BackgroundID ${bkgId},  ${payload}`,
            );
            yield put(updateAssetRemovalFailed());
        }
    }
}

/**
 * This exists solely to select a new background if the currently selected background is deleted
 * @param {string} payload // The deleted background id
 */
function* selectNewBackgroundIfDeleted({ payload }) {
    const selectedId = yield select(getSelectedBackgroundId);
    if (payload === selectedId) {
        const backgrounds = yield select(getAllBackgrounds);
        const firstBackground = backgrounds[0];
        if (firstBackground) {
            yield put(selectBackground(firstBackground.id));
        }
    }
}

export default function* backgroundsSaga() {
    return yield all([
        yield takeEvery(uploadBackgrounds, addBackgrounds),
        yield takeEvery(uploadAndReplaceBackground, replaceBackground),
        yield takeEvery(removeBackground, selectNewBackgroundIfDeleted),
        yield takeEvery(updateBackgroundRemovalModal, updateBackground),
        yield takeEvery(updateAssetRemovalModal, updateAsset),
        yield takeEvery(importPSDStart, uploadPSDAssets),
    ]);
}
