import { createSlice } from '@reduxjs/toolkit';
import { SurfaceType } from 'src/types/surface';

export type SurfaceSliceState = {
    sku: string; // Should contain the MCP Information
    skuVersion: number | undefined; // Should contain the MCP Information
    variables: Record<string, string>; // Should contain the MCP Information

    merchantSku: string | undefined; // Should contain the Variant (PRD) Information
    merchantVersion: number | undefined; // Should contain the Variant (PRD) Information
    merchantVariables: Record<string, string>; // Should contain the Variant (PRD) Information

    lastFailedSku: string | undefined;
    isResolved: boolean;
    isReady: boolean;
    list: SurfaceType[];
    error?: Error;
};

const initialState: SurfaceSliceState = {
    sku: '',
    skuVersion: undefined,
    variables: {},

    merchantSku: undefined,
    merchantVersion: undefined,
    merchantVariables: {},

    lastFailedSku: undefined,
    isResolved: false,
    isReady: false,
    list: [],
};

const slice = createSlice({
    name: 'surfaces',
    initialState,
    reducers: {
        validateEditorSku: () => {},
        validateEditorSkuSuccess: (state, { payload }) => {
            state.sku = payload.sku;
            state.skuVersion = payload.skuVersion;
            state.variables = payload.variables;
            state.merchantSku = payload.merchantSku;
            state.merchantVersion = payload.merchantVersion;
            state.merchantVariables = payload.merchantVariables;
        },
        validateEditorSkuFailed: (state, { payload }) => {
            state.lastFailedSku = payload.sku;
            state.sku = '';
            state.skuVersion = 0;
            state.variables = {};
            state.merchantSku = undefined;
            state.merchantVersion = undefined;
            state.merchantVariables = {};
        },
        changeEditorSku: () => {},
        changeEditorSkuVersion: (state, { payload }) => {
            state.merchantVersion = payload;
        },
        clearEditorSku: (state, { payload }) => {
            state.sku = payload || '';
            state.skuVersion = undefined;
            state.variables = {};
            state.merchantSku = undefined;
            state.merchantVersion = undefined;
            state.merchantVariables = {};
            state.isResolved = false;
            state.isReady = false;
            state.list = [];
        },
        changeEditorSkuSuccess: (state) => {
            state.variables = {};
            state.list = [];
            state.isReady = true;
        },
        changeEditorSkuFailed: (state, { payload }) => {
            state.error = {
                ...payload,
            };
        },
        applyVariables: (state, { payload }) => {
            if (JSON.stringify(state.merchantVariables) !== JSON.stringify(payload.variables)) {
                state.merchantVariables = payload.variables;
                state.isResolved = payload.resolved;
            }
        },
        retrieveSurfacesSuccess: (state, { payload }) => {
            state.list = payload;
            state.isResolved = true;
        },
        retrieveSurfacesFailed: () => {},
        unresolveVariables: (state, { payload }) => {
            state.variables = payload;
            state.isResolved = false;
        },
    },
});

export const reducer = slice.reducer;

export const {
    changeEditorSku,
    changeEditorSkuVersion,
    clearEditorSku,
    changeEditorSkuSuccess,
    changeEditorSkuFailed,
    validateEditorSku,
    validateEditorSkuSuccess,
    validateEditorSkuFailed,
    applyVariables,
    retrieveSurfacesSuccess,
    retrieveSurfacesFailed,
    unresolveVariables,
} = slice.actions;
