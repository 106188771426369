import React, { useCallback, useMemo, useState } from 'react';
import {
    AttributeChangeType,
    GenericSelectionAttribute,
    GenericVariableConfiguration,
    IProductAttributesProps,
    parseChangesToVariableConfiguration,
    ProductAttributesSelector,
} from '@rendering/components';

import { ResourceType } from '@cimpress-technology/generic-selector';
import { AttributesToObject } from 'src/util/misc';

interface IVariables {
    isReady: boolean | undefined;
    sku: string;
    skuVersion: number | undefined;
    variables: Record<string, string> | undefined;
    onChangeVariables: (sku: string, skuVersion: number, changes: AttributeChangeType) => void;
    token: string;
}

const options: IProductAttributesProps['options'] = {
    selector: {
        isColorSwatch: true,
        selectionResource: ResourceType.SURFACE,
        selectWithProductAttributes: false,
    },
    tooltip: {
        show: true,
    },
};

const setInitialValues = (attributes: GenericVariableConfiguration[]) => {
    const configuration: Record<string, GenericSelectionAttribute> = {};
    attributes?.forEach((item) => {
        if (item.key) {
            configuration[item.key] = {
                initialSelection: item.resolvedValue || '',
                isHidden: !item.isDisplayed,
            };
        }
    });
    return configuration;
};

const VariablesV3 = ({ sku, skuVersion, variables, isReady, onChangeVariables, token }: IVariables) => {
    const [genericOptions, setGenericOptions] = useState(options);

    const handleChanges = useCallback(
        (changes: AttributeChangeType) => {
            const newConfiguration = setInitialValues(parseChangesToVariableConfiguration(changes.attributes));
            setGenericOptions((current) =>
                Object.assign({}, current, {
                    selector: { ...current.selector, attributeConfigurations: newConfiguration },
                }),
            );
            const newVariables = changes.attributes?.filter((i) => i.isDisplayed === true);
            onChangeVariables &&
                JSON.stringify(variables) !== JSON.stringify(AttributesToObject(newVariables)) &&
                onChangeVariables(sku, skuVersion || 0, {
                    ...changes,
                    attributes: newVariables,
                });
        },
        [onChangeVariables, sku, skuVersion, variables],
    );
    const render = useMemo(() => {
        return isReady ? (
            <ProductAttributesSelector
                sku={sku}
                skuVersion={skuVersion}
                variables={variables}
                onChange={handleChanges}
                token={token}
                options={genericOptions}
            />
        ) : null;
    }, [sku, skuVersion, variables, handleChanges, isReady, genericOptions, token]);

    return (
        <div className='variables' id={token}>
            {render}
        </div>
    );
};

export default VariablesV3;
