import { combineReducers } from 'redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { changeItemOrder, removeById } from 'src/util/state';
import { BackgroundType } from 'src/types/background';
import { LayerType } from 'src/import/psd/webworker/message';
import { RESIZE_MODAL_MESSAGE } from './resizeModal/resizeModal';

export type BackgroundSliceState = BackgroundType[];

const initialState: BackgroundSliceState = [];

const listSlice = createSlice({
    name: 'list',
    initialState,

    reducers: {
        uploadBackgrounds: (state, { payload }: { payload: BackgroundType[] }) => {
            payload.forEach((background) => {
                state.push({ uploading: true, id: background.id });
            });
        },
        backgroundUploaded: (state, { payload }: { payload: BackgroundType }) => {
            const { id, url, width, height, name, mode } = payload;
            const backgroundIndex = state.findIndex((background) => background.id === id);
            if (backgroundIndex > -1) {
                state[backgroundIndex] = { id, url, width, height, name, mode, uploading: false };
            }
        },
        uploadAndReplaceBackground: (state, { payload }) => {
            const backgroundToReplace = state.find((background) => background.id === payload.id);
            if (backgroundToReplace) {
                backgroundToReplace.uploading = true;
            }
        },
        replaceFailed: (state, { payload }) => {
            const backgroundThatFailed = state.find((background) => background.id === payload);
            if (backgroundThatFailed) {
                backgroundThatFailed.uploading = false;
            }
        },
        removeBackground: removeById,
        changeBackgroundName: (state, { payload }) => {
            const backgroundToChange = state.find((background) => background.id === payload.id);
            if (backgroundToChange) {
                backgroundToChange.name = payload.name;
            }
        },
        changeBackgroundOrder: changeItemOrder,
        cleanDisabledBackgrounds: (state) => {
            state.forEach((bkg) => {
                bkg.isProtected = false;
            });
        },
        disabledEditBackground: (state, { payload }) => {
            const background = state.find((bkg) => bkg.name === payload);
            if (background) {
                background.isProtected = true;
            }
        },
        updateBackgroundById: (state, { payload }) => {
            const background = state.find((bkg) => bkg.id === payload.id);
            if (background) {
                background.url = payload.url;
            }
        },
    },
});

const selectedSlice = createSlice({
    name: 'selectedBackground',
    initialState: '',

    reducers: {
        selectBackground: (state, { payload }) => payload,
    },

    extraReducers: (builder) => {
        builder.addCase(listSlice.actions.uploadBackgrounds, (state, { payload }) => payload[0].id);

        builder.addCase(listSlice.actions.uploadAndReplaceBackground, (state, { payload }) => payload.id);
    },
});

type BackgroundRemovalSliceInitialState = {
    // Presence of backgroundId means that the modal should be shown
    backgroundId?: string; // field use for backgrounds
    assetId?: string; // field use for assets in scene variable
    updatedUrl?: string;
};

const backgroundRemovalSliceInitialState: BackgroundRemovalSliceInitialState = {};

const backgroundRemovalSlice = createSlice({
    name: 'backgroundRemoval',
    initialState: backgroundRemovalSliceInitialState,
    reducers: {
        showModal: (state, { payload }: PayloadAction<{ backgroundId: string }>) => {
            state.backgroundId = payload.backgroundId;
        },
        showModalAsset: (state, { payload }) => {
            state.assetId = payload;
        },
        hideModal: (state) => {
            state.backgroundId = undefined;
        },
        hideModalAsset: (state) => {
            state.assetId = undefined;
        },
        updateBackgroundRemoval: (state, { payload }) => {
            state.updatedUrl = payload;
        },
        updateAssetRemoval: (state, { payload }) => {
            state.updatedUrl = payload;
        },
        updateBackgroundRemovalSuccess: (state) => {
            state.backgroundId = undefined;
            state.updatedUrl = undefined;
        },
        updateBackgroundRemovalFailed: (state) => {
            state.backgroundId = undefined;
            state.updatedUrl = undefined;
        },
        updateAssetRemovalSuccess: (state) => {
            state.assetId = undefined;
            state.updatedUrl = undefined;
        },
        updateAssetRemovalFailed: (state) => {
            state.assetId = undefined;
            state.updatedUrl = undefined;
        },
    },
});

type ResizeModalSliceType = {
    show: boolean;
    message: keyof typeof RESIZE_MODAL_MESSAGE;
};
const resizeModalSlice = createSlice({
    name: 'isResizeModalShown',
    initialState: {
        show: false,
        message: 'large',
    } as ResizeModalSliceType,
    reducers: {
        showModal: (state, { payload }) => {
            state.show = true;
            state.message = payload;
        },
        cancelUpload: (state) => {
            state.show = false;
        },
        acceptResize: (state) => {
            state.show = false;
        },
        declineResize: (state) => {
            state.show = false;
        },
    },
});

type ImportPSDSliceInitialStateType = {
    show: boolean;
    layers: LayerType[];
    resolution: {
        height: number;
        width: number;
    };
    import: string[];
    file?: File;
};

const ImportPSDSliceInitialState: ImportPSDSliceInitialStateType = {
    show: false,
    layers: [],
    import: [],
    resolution: {
        width: 1360,
        height: 1360,
    },
};

const importPSD = createSlice({
    name: 'importPSD',
    initialState: ImportPSDSliceInitialState,
    reducers: {
        showPSDModal: (state, { payload }) => {
            state.show = payload;
        },
        loadPSD: (state, { payload }) => {
            state.file = payload;
            state.show = true;
        },
        selectPSDLayer: (state, { payload }) => {
            const exist = state.layers.find((i) => i.id === payload.id);
            if (exist) {
                state.layers = state.layers.filter((i) => i.id !== payload.id);
            } else if (payload && payload.imageUrl) {
                state.layers.push(payload);
            }
        },
        importPSDStart: () => {},
        importPSDFinish: (state) => {
            state.show = false;
            state.layers = [];
            state.file = undefined;
            state.import = [];
        },
        setResolution: (state, { payload }) => {
            if (payload?.width > 0 && payload?.height > 0) {
                state.resolution = payload;
            }
        },
    },
});

export const reducer = combineReducers({
    selected: selectedSlice.reducer,
    list: listSlice.reducer,
    isResizeModalShown: resizeModalSlice.reducer,
    backgroundRemoval: backgroundRemovalSlice.reducer,
    importPSD: importPSD.reducer,
});

export const uploadBackgrounds = listSlice.actions.uploadBackgrounds;
export const backgroundUploaded = listSlice.actions.backgroundUploaded;
export const uploadAndReplaceBackground = listSlice.actions.uploadAndReplaceBackground;
export const replaceBackgroundFailed = listSlice.actions.replaceFailed;
export const removeBackground = listSlice.actions.removeBackground;
export const selectBackground = selectedSlice.actions.selectBackground;
export const showResizeModal = resizeModalSlice.actions.showModal;
export const cancelResizeModalUpload = resizeModalSlice.actions.cancelUpload;
export const acceptResize = resizeModalSlice.actions.acceptResize;
export const declineResize = resizeModalSlice.actions.declineResize;
export const changeBackgroundName = listSlice.actions.changeBackgroundName;
export const changeBackgroundOrder = listSlice.actions.changeBackgroundOrder;
export const disabledEditBackground = listSlice.actions.disabledEditBackground;
export const cleanDisabledBackgrounds = listSlice.actions.cleanDisabledBackgrounds;
export const updateBackgroundById = listSlice.actions.updateBackgroundById;
export const showBackgroundRemovalModal = backgroundRemovalSlice.actions.showModal;
export const hideBackgroundRemovalModal = backgroundRemovalSlice.actions.hideModal;
export const updateBackgroundRemovalModal = backgroundRemovalSlice.actions.updateBackgroundRemoval;
export const updateBackgroundRemovalSuccess = backgroundRemovalSlice.actions.updateBackgroundRemovalSuccess;
export const updateBackgroundRemovalFailed = backgroundRemovalSlice.actions.updateBackgroundRemovalFailed;
export const showAssetRemovalModal = backgroundRemovalSlice.actions.showModalAsset;
export const hideAssetRemovalModal = backgroundRemovalSlice.actions.hideModalAsset;
export const updateAssetRemovalModal = backgroundRemovalSlice.actions.updateAssetRemoval;
export const updateAssetRemovalSuccess = backgroundRemovalSlice.actions.updateAssetRemovalSuccess;
export const updateAssetRemovalFailed = backgroundRemovalSlice.actions.updateAssetRemovalFailed;

// Import PSD
export const { showPSDModal, importPSDStart, loadPSD, importPSDFinish, selectPSDLayer, setResolution } =
    importPSD.actions;
