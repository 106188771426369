import { toSelectOption } from 'src/util/misc';

export const DEFAULT = 'Use Default';

export const ASSET_ACTIONS = {
    REPLACE: 'REPLACE',
    FILL: 'FILL',
    REMOVE: 'REMOVE',
};
export const ASSET_ACTIONS_OPTIONS = toSelectOption(['REPLACE', 'FILL', 'REMOVE']);

export const DOCUMENT_ACTIONS = {
    BLENDING_MODE: 'blendingMode',
};

export const PURPOSES_OPTIONS = [
    { label: 'Product Full', value: 'Product:Full' },
    { label: 'Product Closeup', value: 'Product:Closeup' },
    { label: 'Product Alternate', value: 'Product:Alternate' },
    { label: 'Design Overlay', value: 'Design:Overlay' },
    { label: 'Design Underlay', value: 'Design:Underlay' },
];

export const MERCHANDISING = {
    value: 'Merchandising',
    label: 'Merchandising',
};

export const SCENE_VARIABLE_TYPES = {
    'Asset Change': 'Asset Change',
    Cropping: 'Cropping',
};
export const SCENE_VARIABLE_TYPES_OPTIONS = toSelectOption(['Asset Change', 'Cropping']);

export const PRODUCT_VARIABLE_TYPES = {
    product: 'Product Option',
    blending: 'Blending Mode',
};
export const PRODUCT_VARIABLE_TYPES_OPTIONS = toSelectOption(['Product Option', 'Blending Mode']);

export const BLENDING_MODES = {
    Normal: 'Normal',
    Multiple: 'Multiply',
};
export const BLENDING_MODES_OPTIONS = toSelectOption(['Normal', 'Multiply']);

export const SURFACE_ACTIONS = {
    INCREMENT: 'INCREMENT',
    DECREMENT: 'DECREMENT',
    REPLACE: 'REPLACE',
};
