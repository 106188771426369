import { ProductInfoType, ProductResponseType, ProductVersion } from 'src/types/product';
import { makeAuthenticatedServiceRequest, makePublicServiceRequest } from './servicesClient';
import { AxiosResponse } from 'axios';

const ProductType: Record<string, string> = {
    CIM: 'CIM',
    PRD: 'PRD',
    VIP: 'VIP',
};

const ENDPOINT = 'https://introduction.products.cimpress.io';
const PRODUCT_ORCHESTRATION = 'https://merchant-orchestration-api.products.cimpress.io';
const PRODUCT_ENDPOINT = 'api.products.cimpress.io/v1/products';
const PRD_CATALOG = 'https://catalog.products.vpsvc.com';
const BINDING_PRODUCT_SERVICE = 'https://bes.products.cimpress.io/api/v2';

function parseProductData(result: AxiosResponse<ProductResponseType> | void) {
    if (!result) {
        return {};
    }

    return {
        name: result.data.name,
        // fallback to show created date when modified is not defined as it might be first iteration of the product
        modified: result.data.modified || result.data.auditInfo?.modifiedAt || result.data.createdAt,
        description: result.data.description,
        options: result.data.options,
    };
}

export function retrieveProductData({ sku, skuVersion }: Omit<ProductInfoType, 'attributes'>) {
    // eslint-disable-line import/prefer-default-export
    const type = ProductType[sku.trim().substring(0, 3)];
    let url = `${ENDPOINT}/v3/mcpSkus/${sku}`;

    if (skuVersion) {
        url =
            type === 'CIM'
                ? `https://${PRODUCT_ENDPOINT}/${sku}/versions/${skuVersion}`
                : `${PRODUCT_ORCHESTRATION}/v2/products/${sku}/versions/${skuVersion}`;
    }

    return makeAuthenticatedServiceRequest({ url }).then(parseProductData);
}

function mapProductVersion(res: ProductVersion[]) {
    return res
        .sort((a, b) => (b.version || 0) - (a.version || 0))
        .map((proVer) => ({
            productId: proVer.productId,
            version: proVer.version,
            status: proVer.status.toLowerCase(),
            current: proVer.current,
            name: proVer.name,
        }));
}

/**
 * @description This method is using multiple request to build our data because limitations from product orchestration API.
 * We are expecting this to change in the future, but for now this is the only way to do it.
 */
export async function getPRDProductVersions(sku: string) {
    const url = `${PRODUCT_ORCHESTRATION}/v2/products/${sku}/latest-version`;
    const statusVersionURL = `${PRODUCT_ORCHESTRATION}/v2/products/${sku}/versions`;

    const latest = await makeAuthenticatedServiceRequest({ url });
    const versions = Array(latest.data.version - 1)
        .fill('')
        .map((_, i) => i + 1);

    const promises = versions.map((v) => makeAuthenticatedServiceRequest({ url: `${statusVersionURL}/${v}` }));

    const statuses = await Promise.allSettled(promises);

    const data = [latest.data];
    statuses.forEach((v, i) => {
        if (v.status === 'fulfilled') {
            data.push(v.value.data);
        } else {
            data.push({
                version: i + 1,
                status: 'error',
                productId: sku,
            });
        }
    });

    return mapProductVersion(data);
}

export function getProductVersions(sku: string) {
    const type = ProductType[sku.trim().substring(0, 3)];

    if (type === 'VIP') {
        return Promise.resolve([]);
    }

    if (type === ProductType.PRD) {
        return getPRDProductVersions(sku);
    }

    const url = `https://${PRODUCT_ENDPOINT}/${sku}/versions`;

    return makeAuthenticatedServiceRequest({ url, method: 'get' }).then(
        // eslint-disable-next-line no-underscore-dangle
        (res) => mapProductVersion(res.data._embedded.item),
    );
}

/**
 * Retrieves mcpSku and/or version of the mcpSku related to the vistaprint platform sku + version.
 * @param options
 * @param options.sku - The platformID.
 * @param options.version - The platformID version.
 * @returns - The MCP Sku and version related to the platformId .
 */
export function retrievePlatformId({ sku, skuVersion, attributes }: ProductInfoType) {
    if (skuVersion) {
        let url = `${BINDING_PRODUCT_SERVICE}/bindings:resolverootconfiguration?productId=${sku}&version=${skuVersion}`;
        Object.entries(attributes ?? {}).forEach(
            ([key, value]) => (url += `&${encodeURIComponent(`selections[${key}]`)}=${encodeURIComponent(value)}`),
        );

        return makeAuthenticatedServiceRequest({ url }).then((response) => ({
            merchantSku: response.data.rootConfiguration.productId,
            merchantVersion: response.data.rootConfiguration.version,
            merchantVariables: response.data.rootConfiguration.selections,
        }));
    }

    return makePublicServiceRequest({
        url: `${PRD_CATALOG}/api/products/${sku}/mcpProductIdentifier?requestor=scene-maker`,
    }).then((response) => ({
        merchantSku: response.data.productId || response.data.skuId,
        merchantVersion: response.data.version,
        merchantVariables: undefined,
    }));
}
